import { formatDuration, intervalToDuration } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useIdleTimer } from 'react-idle-timer'

import { Button } from '../../../../Components'
import { ModalContainer } from '../../../../Components/ModalContainer'
import { Typography } from '../../../../Components/Typography'
import { useTranslationDecorator } from '../../../../Hooks/useTranslationDecorator'
import { localeForDateFn } from '../../../../Lib/Formatters/DateFormatter'
import { AuthContext } from '../../../../Providers'
import Styles from './styles.module.scss'

export const IdleTimerModal = (): JSX.Element => {
  const { t, locale } = useTranslationDecorator()
  const { logout } = useContext(AuthContext)

  const timeout = 3600000 // 60 minutes
  const promptBeforeIdle = 600000 // 10 minutes

  const [remaining, setRemaining] = useState<number>(timeout)
  const [open, setOpen] = useState<boolean>(false)

  const onIdle = (): void => {
    setOpen(false)
    logout()
  }

  const onActive = (): void => {
    setOpen(false)
  }

  const onPrompt = (): void => {
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = (): void => {
    activate()
  }

  const formatTime = (timeInSeconds: number): string => {
    const duration = intervalToDuration({ start: 0, end: timeInSeconds * 1000 })
    const format = ['minutes', 'seconds']
    return formatDuration(duration, { format, zero: false, delimiter: t(' and '), locale: localeForDateFn(locale) })
  }

  return (
    <ModalContainer
      title={t('Confirmation popup')}
      show={open}
      onClose={handleStillHere}
      className={Styles.modal}
      id="idleTimer-modal"
    >
      <div
        className={Styles.container}
        onClick={(event): void => {
          event.stopPropagation()
          event.preventDefault()
        }}
      >
        <Typography className={Styles.title} variant="h1" element="p">
          {t('Would you like to stay logged in?')}
        </Typography>
        <Typography variant="body-1" className={Styles.description}>
          <Trans i18nKey="idleMessage">You will be logged out in {{ remainingTime: formatTime(remaining) }}</Trans>
        </Typography>

        <Button id="modal-confirm-button" onClick={handleStillHere} fullWidth>
          {t('Stay logged in')}
        </Button>
      </div>
    </ModalContainer>
  )
}
