import { useEffect } from 'react'
import { useLocation } from 'react-router'

import { TRoutes } from '../../Layouts/Unauthorized/interfaces'

export const getRouteNameFromPath = (routes: TRoutes, path: string): string => {
  const routeName = Object.values(routes).find((route) => {
    const regex = route
      .split('/')
      .map((routePart) => (routePart.startsWith(':') ? '[^/]+' : routePart))
      .join('/')

    return new RegExp(`^${regex}$`).test(path)
  })

  return routeName ?? path
}

export const useTrackPageView = (routes: TRoutes): void => {
  const { pathname } = useLocation()

  useEffect(() => {
    const routeName = getRouteNameFromPath(routes, pathname)

    if (window.newrelic) window.newrelic.addPageAction('pageView', { routeName })
  }, [routes, pathname])
}
