import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '../Icon'
import { ModalContainer } from '../ModalContainer'
import { Typography } from '../Typography'
import Styles from './styles.module.scss'

export const JWTDebugger: React.FC = (): JSX.Element | null => {
  const { t } = useTranslation()
  const [showModal, setShowModal] = useState<boolean>(false)
  const jwt = window.localStorage.getItem('accessToken')

  let decoded = ''

  if (jwt !== null) {
    const encoded = jwt?.split('.')[1] || '{}'
    decoded = JSON.stringify(JSON.parse(atob(encoded)), null, 2)
  }

  return (
    <React.Fragment>
      <div
        className={Styles.jwTDebugger}
        onClick={(): void => setShowModal(!showModal)}
        data-testid="JWTDebugger"
        role="button"
        tabIndex={0}
        aria-label="JWT Debugger"
      >
        <Icon name="bug-fix" />
        <Typography variant="body-1">{t('JWT')}</Typography>
      </div>

      <ModalContainer title="testing" onClose={(): void => setShowModal(false)} fullWidth fullHeight show={showModal}>
        <h1>JWT</h1>
        <pre>{decoded}</pre>
      </ModalContainer>
    </React.Fragment>
  )
}
