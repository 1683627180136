import React from 'react'
import { useTranslation } from 'react-i18next'

import { Hint } from '../../../Hint'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const MaybeWithMaxLengthHint: React.FC<IProps> = ({ maxLength, label, children }): JSX.Element => {
  const { t } = useTranslation()

  const text = ((): string | undefined => {
    if (maxLength && maxLength.hint) return maxLength.hint

    if (maxLength && label && !maxLength.hint) {
      return t('The {{ fieldName }} field can contain a maximum of {{ count }} characters', {
        fieldName: label.toLowerCase(),
        count: maxLength.length,
      })
    }

    return undefined
  })()

  if (!text) return children

  return (
    <Hint className={Styles.hint} hint={text}>
      {children}
    </Hint>
  )
}
