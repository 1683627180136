import React from 'react'

import { Icon } from '../../../../Icon'
import { Typography } from '../../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const MenuItem: React.FC<IProps> = ({ id, label, onClick, onHover, icon, ...rest }): JSX.Element => {
  return (
    <div id={id} className={Styles.menuItemWrapper} role="button" tabIndex={0} onClick={onClick} {...rest}>
      <div
        className={Styles.menuItem}
        onMouseEnter={(event): void => onHover(event, id, 'enter')}
        onMouseLeave={(event): void => onHover(event, id, 'leave')}
      >
        <Icon name={icon} className={Styles.icon} />
        <Typography className={Styles.label} variant="h2">
          {label}
        </Typography>
        <Icon className={Styles.chevronRight} width={12} height={12} name="chevron-right" />
      </div>
    </div>
  )
}
