import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import {
  IContractStatusEnum,
  useGetAccessUrlMutation,
  useGetSplitBillingContractLazyQuery,
} from '../../../../Lib/graphql'
import { Active } from './Active'
import { Cancelled } from './Cancelled'
import { Intro } from './Intro'
import { Processing } from './Processing'
import Styles from './styles.module.scss'

export const RequestSplitBilling = (): JSX.Element => {
  const { t } = useTranslation()
  const [mutation] = useGetAccessUrlMutation({ variables: { input: {} } })
  const [polling, setPolling] = useState<boolean>(false)
  const [contractId, setContractId] = useState<string | null | undefined>(null)
  const [accessUrl, setAccessUrl] = useState<string | null | undefined>(null)
  const [pollContractQuery, { data: contractData }] = useGetSplitBillingContractLazyQuery({ pollInterval: 2000 })
  const [contractStatus, setContractStatus] = useState<IContractStatusEnum>(IContractStatusEnum.NEW)

  const handleOnClick = (): void => {
    setContractStatus(IContractStatusEnum.ASSIGNED)
    // We must open the window now, otherwise the popup blocking feature
    // of the browser does not allow to do this anymore
    const myWindow = window.open(`${window.location.origin}/redirecting`)
    mutation()
      .then((result) => {
        const url = result?.data?.splitBillingContracts?.getAccessUrl?.accessUrl
        const id = result?.data?.splitBillingContracts?.getAccessUrl?.contract?.splitBillingContractId

        setContractId(id)
        setAccessUrl(url)

        if (myWindow && url) myWindow.location.href = url
        else {
          toast.error(t('Something went wrong while getting the URL. Please try again later...'), { autoClose: false })
          myWindow && myWindow.close()
        }

        return false
      })
      .catch((error) => {
        toast.error(t('Something went wrong while getting the URL. Please try again later...'), { autoClose: false })
        console.error(error)
      })
  }

  useEffect(() => {
    if (!contractData) return
    setContractStatus(contractData?.me?.splitBillingContract?.status.value || IContractStatusEnum.NEW)
  }, [contractData])

  useEffect(() => {
    if (contractStatus === IContractStatusEnum.ACTIVE || IContractStatusEnum.CANCELLED) setPolling(false)
  }, [contractStatus])

  useEffect(() => {
    if (!contractId || polling) return
    setPolling(true)
    pollContractQuery({ variables: { contractId } })
  }, [contractId, pollContractQuery, polling])

  const render = (): JSX.Element => {
    switch (contractStatus) {
      case 'ASSIGNED': // active na form ok
      case 'INVITED': // active na form ok
        return <Processing accessUrl={accessUrl} />
      case 'ACTIVE': // active na form ok
        return <Active />
      case 'CANCELLED': // no form fail
        return <Cancelled />
      default:
        return <Intro onClick={handleOnClick} />
    }
  }

  return <div className={Styles.container}>{render()}</div>
}
