import { format } from 'date-fns'
import React, { useContext } from 'react'

import { ButtonLink } from '../../../../Components'
import { Typography } from '../../../../Components/Typography'
import { useTranslationDecorator } from '../../../../Hooks/useTranslationDecorator'
import { localeForDateFn } from '../../../../Lib/Formatters/DateFormatter'
import { MediaQueriesContext } from '../../../../Providers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Invoice: React.FC<IProps> = ({ date, label, xlsUrl, pdfUrl }): JSX.Element => {
  const { t, locale } = useTranslationDecorator()
  const formattedDate = format(date, 'd MMMM yyyy', { locale: localeForDateFn(locale) })
  const { isLGScreen } = useContext(MediaQueriesContext)
  const isTabletOrMobileScreen = !isLGScreen

  return (
    <div className={Styles.row} data-testid={`invoice-${formattedDate.replaceAll(' ', '-')}`}>
      <div>
        <Typography data-testid="date" className={Styles.date} variant="body-2">
          {formattedDate}
        </Typography>
        <Typography data-testid="status" className={Styles.label} variant="h1" element="p">
          {label}
        </Typography>
      </div>

      {(!!pdfUrl || !!xlsUrl) && (
        <div className={Styles.buttonsWrapper}>
          {pdfUrl && (
            <ButtonLink
              ariaLabel={t('Download')}
              data-testid={`download-pdf-${formattedDate}`}
              className={Styles.link}
              variant="secondary"
              size={isTabletOrMobileScreen ? 'extraSmall' : 'small'}
              href={pdfUrl}
              displayExternalLinkIcon={false}
            >
              {t('Download')}
            </ButtonLink>
          )}

          {xlsUrl && (
            <ButtonLink
              ariaLabel={t('Specification')}
              data-testid={`download-xls-${formattedDate}`}
              className={Styles.link}
              variant="secondary"
              size={isTabletOrMobileScreen ? 'extraSmall' : 'small'}
              href={xlsUrl}
              displayExternalLinkIcon={false}
            >
              {t('Specification')}
            </ButtonLink>
          )}
        </div>
      )}
    </div>
  )
}
