import React from 'react'
import { useTranslation } from 'react-i18next'

import { Hint } from '../../Hint'
import { Typography } from '../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Label: React.FC<IProps> = (props): JSX.Element => {
  const { t } = useTranslation()
  const {
    id,
    children,
    labelCssClasses = [],
    disabled,
    errors,
    label,
    inline,
    hint,
    isOptional,
    width = '100%',
  } = props
  const css = [Styles.label, ...labelCssClasses]
  if (disabled) css.push(Styles.disabled)
  if (inline) css.push(Styles.inline)

  const compactedCss = css.filter((entry) => entry !== '').join(' ')

  const maybeRenderError = (): JSX.Element | null => {
    if (!errors || !errors.length) return null
    return (
      <Typography variant="body-3" className={Styles.error}>
        {errors.join(', ')}
      </Typography>
    )
  }

  const labelNode = (
    <React.Fragment>
      {label} {isOptional && ` (${t('optional')})`}
    </React.Fragment>
  )

  return (
    /* eslint-disable-next-line reisbalans-rules/prevent-vanilla-html-elements */
    <label htmlFor={id} className={compactedCss} style={{ width }}>
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      {hint ? <Hint hint={hint}>{labelNode}</Hint> : <React.Fragment>{labelNode}</React.Fragment>}
      {children}
      {maybeRenderError()}
    </label>
  )
}
