type TPlatform = 'reisbalans' | 'shuttel'

export const platform: TPlatform =
  import.meta.env.REACT_APP_PLATFORM?.toLowerCase() === 'shuttel' ? 'shuttel' : 'reisbalans'

const androidUrl =
  platform === 'shuttel'
    ? 'https://play.google.com/store/apps/details?id=nl.rb.mobiliteitsapp&hl=nl&gl=US'
    : 'https://play.google.com/store/apps/details?id=com.reisbalansmobile&hl=nl&gl=US'

const iosUrl =
  platform === 'shuttel'
    ? 'https://apps.apple.com/nl/app/mobiliteitsapp/id6447185248'
    : 'https://apps.apple.com/nl/app/reisbalans-app/id1423037885'

export const navigateToApp = (store: 'android' | 'ios'): void => {
  window.open(store === 'android' ? androidUrl : iosUrl, '_blank')
}
