import React, { useContext, useState } from 'react'

import { ThemeContext } from '../../Providers'
import { Form, Input } from '../FormComponents'
import Styles from './styles.module.scss'

export const ThemeSwitcher: React.FC = (): JSX.Element | null => {
  const { overrideHostname, setOverrideHostname } = useContext(ThemeContext)
  const [newHostname, setNewHostname] = useState<string>()

  const handleSubmit = (): void => {
    if (!newHostname) return
    setOverrideHostname(newHostname)
  }

  const handelOverrideHostname = (value: string): void => {
    setNewHostname(value)
  }

  return (
    <div className={Styles.themeSwitcher}>
      <Form onSubmit={handleSubmit} data-testid="hostnameOverrideForm">
        <Input
          id="hostnameOverride"
          data-testid="hostnameOverride"
          type="text"
          placeholder="Hostname override"
          defaultValue={overrideHostname}
          onChange={handelOverrideHostname}
          className={Styles.themeInput}
        />
      </Form>
    </div>
  )
}
