import React, { useContext, useMemo, useState } from 'react'

import { MediaQueriesContext } from '../MediaQueriesProvider'
// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import { ISidebarCollapsedContext } from './interfaces'

export const SidebarCollapsedContext = React.createContext<ISidebarCollapsedContext>({
  sidebarCollapsed: undefined,
  setSidebarCollapsed: (_collapsed: boolean) => null,
  toggleSidebarCollapsed: () => null,
  sidebarPinned: false,
  setSidebarPinned: () => {},
})

export const SidebarCollapsedProvider: React.FC<{ children: React.ReactNode }> = ({ children }): JSX.Element | null => {
  const { isLGScreen } = useContext(MediaQueriesContext)
  const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(!isLGScreen)
  const [sidebarPinned, setSidebarPinned] = useState<boolean>(isLGScreen)

  const toggleSidebarCollapsed = (): void => {
    setSidebarCollapsed((previousValue) => !previousValue)
  }

  const options = useMemo(() => {
    return {
      sidebarCollapsed,
      setSidebarCollapsed,
      toggleSidebarCollapsed,
      sidebarPinned,
      setSidebarPinned,
    }
  }, [sidebarCollapsed, sidebarPinned])

  if (sidebarCollapsed === undefined) return null

  return <SidebarCollapsedContext.Provider value={options}>{children}</SidebarCollapsedContext.Provider>
}
