import React, { FunctionComponent } from 'react'
import { components, OptionProps, SingleValue } from 'react-select'

import { Icon } from '../../../../Icon'
import { THoverType } from '../../../../ProfileSidebar/components/SettingsListComponent/MenuItem/interfaces'
import { Typography } from '../../../../Typography'
import { IOption } from '../../interfaces'
import Styles from './styles.module.scss'

export const OptionComponent: FunctionComponent<OptionProps<SingleValue<IOption>, false>> = (
  innerProps
): JSX.Element => {
  const { data } = innerProps
  const {
    label,
    value: { id, placeType, location },
  } = data as IOption

  const generateId = (identifier: string): string => `location-${identifier}`

  const onHover = (event: React.MouseEvent<HTMLDivElement>, type: THoverType): void => {
    const { options } = innerProps
    const indexOfCurrentElement = options.indexOf(data)
    if (indexOfCurrentElement === 0) return

    const previousOption = options[indexOfCurrentElement - 1] as IOption
    const previousElement = document.getElementById(generateId(previousOption.value.id))
    if (!previousElement) return

    type === 'enter'
      ? previousElement.classList.add(Styles.preHoverItem)
      : previousElement.classList.remove(Styles.preHoverItem)
  }

  return (
    <components.Option {...innerProps} className={Styles.option}>
      <div
        key={id}
        id={generateId(id)}
        className={Styles.wrapper}
        onMouseEnter={(event): void => onHover(event, 'enter')}
        onMouseLeave={(event): void => onHover(event, 'leave')}
      >
        <Icon width={16} height={16} className={Styles.icon} name={placeType === 'favorite' ? 'star' : 'location'} />
        <div className={Styles.locationWrapper}>
          <Typography data-testid="location-name" variant="h2" className={Styles.label}>
            {label}
          </Typography>
          <Typography variant="body-3" className={Styles.location}>
            {location}
          </Typography>
        </div>
      </div>
    </components.Option>
  )
}
