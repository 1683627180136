import React, { useState } from 'react'

import { Icon } from '../../Icon'
import { Typography } from '../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Hint: React.FC<IProps> = ({ hint, className, children }): JSX.Element => {
  const [showHint, setShowHint] = useState<boolean>(false)

  const classes = ((): string => {
    const classNames: string[] = [Styles.accordionContent]

    if (showHint) classNames.push(Styles.visible)
    if (className && showHint) classNames.push(className)

    return classNames.join(' ')
  })()

  return (
    <div className={Styles.hintContainer}>
      <div className={Styles.iconWrapper}>
        {children}

        <Icon
          width={16}
          height={16}
          className={Styles.icon}
          data-testid="hint-icon"
          name="info-circle"
          onClick={(event): void => {
            event.preventDefault()
            setShowHint((prev) => !prev)
          }}
        />
      </div>

      <div className={classes}>
        <div className={Styles.accordionInner}>
          <Typography variant="body-2" className={Styles.hint}>
            {hint}
          </Typography>
        </div>
      </div>
    </div>
  )
}
