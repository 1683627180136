import { selectedSectionsDataKey } from '../../Pages/Expenses/Overview/Components/ExpensesTopBar/FilterModal/helper'
import { TFilterEvents, TMobilityEventsQueryArguments } from './interfaces'

const mobilityEventsFilterOptionsKey = 'mobilityEventsFilterOptionsKey.v1.4.0'
export const mobilityEventsQueryArgumentsKey = 'mobilityEventsQueryArguments.v1.4.0'

export const saveFilterOptions = (filterOptions: TFilterEvents): void => {
  window.sessionStorage.setItem(mobilityEventsFilterOptionsKey, JSON.stringify(filterOptions))
}

export const getFilterOptions = (): TFilterEvents | null => {
  const parsedObject: TFilterEvents = JSON.parse(window.sessionStorage.getItem(mobilityEventsFilterOptionsKey) || '{}')

  if (Object.keys(parsedObject).length === 0) return null

  const { dates } = parsedObject

  return {
    ...parsedObject,
    ...(!!dates?.period && {
      dates: {
        ...dates,
        period: {
          from: new Date(dates.period.from),
          to: new Date(dates.period.to),
        },
      },
    }),
  }
}

export const getMobilityEventsQueryArgumentsFromStorage = (): TMobilityEventsQueryArguments | null => {
  const storedValue = window.sessionStorage.getItem(mobilityEventsQueryArgumentsKey)

  return storedValue ? JSON.parse(storedValue) : null
}

// TODO: refactor so that the other function in useFilterHelper is using this one.
export const getMobilityEventsQueryArguments = (): TMobilityEventsQueryArguments => {
  const parsedValue = getMobilityEventsQueryArgumentsFromStorage()

  return {
    limit: parsedValue?.limit || 20,
    offset: parsedValue?.offset || 0,
    happenedBetween: parsedValue?.happenedBetween,
    filterByStatuses: parsedValue?.filterByStatuses || [],
    modalities: parsedValue?.modalities || [],
    subCategories: parsedValue?.subCategories || [],
    travelPurposes: parsedValue?.travelPurposes || [],
  }
}

export const hasActiveFilters = (): boolean => {
  const filter = getMobilityEventsQueryArguments()

  return (
    (filter.modalities && filter.modalities.length > 0) ||
    (filter.filterByStatuses && filter.filterByStatuses.length > 0) ||
    (filter.travelPurposes && filter.travelPurposes.length > 0) ||
    (filter.subCategories && filter.subCategories.length > 0) ||
    false
  )
}

export const saveMobilityEventsQueryArguments = (input: TMobilityEventsQueryArguments): void => {
  window.sessionStorage.setItem(mobilityEventsQueryArgumentsKey, JSON.stringify(input))
}

export const clearStorageFromAllRelatedFilterData = (): void => {
  window.sessionStorage.removeItem(mobilityEventsFilterOptionsKey)
  window.sessionStorage.removeItem(mobilityEventsQueryArgumentsKey)
  window.sessionStorage.removeItem(selectedSectionsDataKey)
}
