import { StoreObject } from '@apollo/client'

export const MESH_CONTEXT_NAME = 'mesh'

export interface ICustomResponseObject extends Readonly<StoreObject> {
  create?: {
    taxiReservation?: {
      id?: unknown
    }
    permissions?: unknown
    id?: unknown
  }
  details?: {
    placeId: string
  }
  suggest?: {
    placeId: string
  }
  name?: {
    value?: string
  }
}
