import React from 'react'

import { IProps } from './interface'
import Styles from './styles.module.scss'

export const SideDrawerContainer: React.FC<IProps> = ({ children, ...otherProps }) => {
  return (
    <div className={Styles.sideDrawerContainer} {...otherProps}>
      {children}
    </div>
  )
}
