import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ListItemButton } from '../../../../Components/ListItemButton'
import { ListWrapper } from '../../../../Components/ListWrapper'
import { ROUTE_NAMES } from '../../../../Layouts/Authorized/interfaces'
import { routes } from '../../../../Layouts/Authorized/routes'
import { Invoice } from '../../Components/Invoice'
import { IProps as IInvoiceProps } from '../../Components/Invoice/interfaces'
import { Title } from '../../Components/Title'
import { mapECurringInvoice } from '../../helpers'
import { IProps } from './interfaces'

export const Invoices: React.FC<IProps> = ({ invoices }): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const sortedInvoices = [...invoices].sort(
    (first, second) => new Date(second.invoiceDate).getTime() - new Date(first.invoiceDate).getTime()
  )

  const mappedInvoices: IInvoiceProps[] = sortedInvoices.map(mapECurringInvoice)

  return (
    <div data-testid="invoices-container">
      <Title title={t('Invoices')} />

      <ListWrapper id="invoices">
        {mappedInvoices.slice(0, 3).map((invoice, index) => {
          return <Invoice key={`invoice-${index}`} {...invoice} />
        })}
        {mappedInvoices.length > 3 && (
          <ListItemButton
            label={t('See all invoices')}
            onClick={(): void => navigate(routes[ROUTE_NAMES.PAYMENTS_INVOICES])}
          />
        )}
      </ListWrapper>
    </div>
  )
}
