import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ListItemButton } from '../../../../Components/ListItemButton'
import { ListWrapper } from '../../../../Components/ListWrapper'
import { Typography } from '../../../../Components/Typography'
import { ROUTE_NAMES } from '../../../../Layouts/Authorized/interfaces'
import { routes } from '../../../../Layouts/Authorized/routes'
import { Invoice } from '../../Components/Invoice'
import { IProps as IInvoiceProps } from '../../Components/Invoice/interfaces'
import { mapTweakyInvoice } from '../../helpers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Invoices: React.FC<IProps> = ({ invoices }): JSX.Element => {
  const { t } = useTranslation()
  const limit = 3
  const navigate = useNavigate()
  const sortedInvoices = [...invoices].sort(
    (first, second) => new Date(second.invoiceDate).getTime() - new Date(first.invoiceDate).getTime()
  )

  const mappedInvoices: IInvoiceProps[] = sortedInvoices.map(mapTweakyInvoice)

  return (
    <div data-testid="invoices-container">
      <Typography className={Styles.title} variant="body-2">
        {t('Invoices')}
      </Typography>
      <ListWrapper id="invoices">
        {mappedInvoices.slice(0, limit).map((invoice, index) => {
          return <Invoice key={`invoice-${index}`} {...invoice} />
        })}
        {mappedInvoices.length > limit && (
          <ListItemButton
            label={t('See all invoices')}
            onClick={(): void => navigate(routes[ROUTE_NAMES.SPLIT_BILLING_PAYMENTS_INVOICES])}
          />
        )}
      </ListWrapper>
    </div>
  )
}
