import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const PillButton: React.FC<IProps> = ({ label, className, ...otherProps }): JSX.Element => {
  return (
    /* eslint-disable-next-line reisbalans-rules/prevent-vanilla-html-elements */
    <button type="button" className={`${Styles.pillButton} ${className}`} {...otherProps}>
      {label}
    </button>
  )
}
