import { t } from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router'

import { Link } from '../../../HTMLComponents'
import { Icon } from '../../../Icon'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const FullPathBreadcrumbs: React.FC<IProps> = ({ breadcrumbs }): JSX.Element => {
  const navigate = useNavigate()

  const Divider: JSX.Element = (
    <span className={Styles.divider}>
      <Icon name="chevron-right" width={12} height={12} />
    </span>
  )

  return (
    <div className={Styles.fullPathBreadcrumbs} data-testid="fullPathBreadcrumbs">
      <div
        role="button"
        tabIndex={0}
        aria-label={t('Home')}
        onClick={(): void => {
          navigate('/')
        }}
        data-testid="homeButton"
        className={Styles.homeButton}
      >
        <Icon name="house" width={18} height={18} />
      </div>

      {breadcrumbs.map((crumb, index) => {
        if (index === breadcrumbs.length - 1) {
          return (
            <React.Fragment key={`${crumb.title.replaceAll(' ', '_')}-${index}`}>
              {Divider}

              <div className={Styles.currentPage}>{crumb.title}</div>
            </React.Fragment>
          )
        }

        return (
          <React.Fragment key={`${crumb.title.replaceAll(' ', '_')}-${index}`}>
            {Divider}

            <Link
              withUnderline={false}
              onClick={(): void => {
                navigate(crumb.url || '/')
              }}
              data-testid={`${crumb.title.replaceAll(' ', '-')}-crumb`}
              id={`${crumb.title.replaceAll(' ', '-')}-crumb`}
              ariaLabel={t(`Crumb to go to the ${crumb.title} page`)}
            >
              {crumb.title}
            </Link>
          </React.Fragment>
        )
      })}
    </div>
  )
}
