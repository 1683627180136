import React from 'react'

import { Typography } from '../../../Typography'
import { ErrorsSection } from '../ErrorsSection'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Header: React.FC<IProps> = ({ title, subTitle, errors }): JSX.Element => {
  return (
    <div className={Styles.header}>
      <Typography variant="h1-large" element="h2" className={Styles.title}>
        {title}
      </Typography>

      {subTitle && (
        <Typography variant="body-2" element="div" className={Styles.subTitle}>
          {subTitle}
        </Typography>
      )}

      <ErrorsSection errors={errors} />
    </div>
  )
}
