import React, { useCallback, useEffect, useState } from 'react'

import { Icon } from '../../../Icon'
import { IProps } from './interface'
import Styles from './styles.module.scss'

export const PinMarker: React.FC<IProps> = ({ mapRef }): JSX.Element => {
  const [isPinned, setPinned] = useState(true)

  const handleClick = useCallback(() => {
    setPinned((prev) => !prev)
  }, [])

  const handlePinDownOnOutsideDrag = (): void => {
    setPinned(true)
  }

  useEffect(() => {
    const map = mapRef.current
    if (!map) return

    map.addEventListener('touchstart', handleClick)
    map.addEventListener('touchend', handleClick)
    map.addEventListener('mousedown', handleClick)
    // To set the pin down when the user drags outside of the container
    window.addEventListener('mouseup', handlePinDownOnOutsideDrag)

    return () => {
      map.removeEventListener('touchstart', handleClick)
      map.removeEventListener('touchend', handleClick)
      map.removeEventListener('mousedown', handleClick)
      window.removeEventListener('mouseup', handlePinDownOnOutsideDrag)
    }
  }, [handleClick, mapRef])

  return (
    <div className={`${Styles.wrapper} ${!isPinned ? Styles.pinned : ''}`}>
      <Icon name="pin-line" className={Styles.icon} />

      <div className={Styles.dot} />
    </div>
  )
}
