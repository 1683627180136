import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Icon } from '../../Icon'
import { Typography } from '../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Task: React.FC<IProps> = ({ title, message, href, index, id, onClick }): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div
      id={id}
      data-testid={id}
      className={Styles.task}
      role="button"
      onClick={(): void => {
        if (onClick) onClick()
        if (href) navigate(href)
      }}
      tabIndex={index}
    >
      <div className={Styles.innerWrapper}>
        <Typography variant="h2" element="p" className={Styles.title}>
          {title || t('Task')}
        </Typography>
        <Icon className={Styles.chevronIcon} name="chevron-right" width={12} height={12} />
      </div>
      <Typography variant="body-3" className={Styles.messageWrapper}>
        {message}
      </Typography>
    </div>
  )
}
