import { ROUTE_NAMES, TRoutes } from './interfaces'

export const routes: TRoutes = {
  [ROUTE_NAMES.ERROR]: '/error',
  [ROUTE_NAMES.LOGIN]: '/',
  [ROUTE_NAMES.MFA_CONFIGURE]: '/mfa/configure',
  [ROUTE_NAMES.MFA_LOGIN]: '/mfa/login',
  [ROUTE_NAMES.HELP]: '/help',
  [ROUTE_NAMES.FORGOT_PASSWORD]: '/password/new',
  [ROUTE_NAMES.WELCOME]: '/welcome/:inviteToken',
  [ROUTE_NAMES.RESET_PASSWORD]: '/welcome/password',
  [ROUTE_NAMES.REAUTHORIZE]: '/reauthorize',
  [ROUTE_NAMES.SESSION_EXPIRED]: '/session_expired',
}
