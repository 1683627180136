import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Button } from '../../../../../Components'
import { ConfirmModal } from '../../../../../Components/ConfirmModal'
import { useMutationHelper } from '../../../../../Hooks/useMutationHelper'
import { useRevokeDirectDebitMandateMutation, useRevokeSplitBillingContractsMutation } from '../../../../../Lib/graphql'
import { MediaQueriesContext } from '../../../../../Providers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Revoke: React.FC<IProps> = ({ mandateId, variant }): JSX.Element => {
  const { t } = useTranslation()
  const { toastify } = useMutationHelper()
  const { isLGScreen } = useContext(MediaQueriesContext)
  const [show, setShow] = useState<boolean>(false)
  const [revokeDirectDebitMandateMutation, { loading: loadingDirectDebitMutation }] =
    useRevokeDirectDebitMandateMutation()
  const isTabletOrMobileScreen = !isLGScreen

  const [revokeContract, { loading: loadingSplitBillingMutation }] = useRevokeSplitBillingContractsMutation()
  const isLoading = loadingDirectDebitMutation || loadingSplitBillingMutation

  const revokeSplitBillingContract = (): void => {
    revokeContract({
      variables: {
        splitBillingContractIds: [mandateId],
      },
    })
      .then((response) => {
        toastify(response.data?.splitBillingContracts?.revoke?.result)
      })
      .catch((error) => {
        toast.error(t('We encountered an error during submit, please try again later...'), { autoClose: false })
        console.error(error)
      })
  }

  const revokeDirectDebitMandate = (): void => {
    revokeDirectDebitMandateMutation({
      variables: {
        id: mandateId,
      },
    })
      .then((response): void => {
        toastify(response.data?.directDebitMandates?.revoke?.result)
        setShow(false)
      })
      .catch((error): void => {
        toast.error(t('We encountered an error during submit, please try again later...'), { autoClose: false })
        console.error({ error })
      })
  }

  const revoke = (): void => {
    if (variant === 'splitBilling') revokeSplitBillingContract()
    else revokeDirectDebitMandate()
  }

  const confirmMessage =
    variant === 'splitBilling'
      ? t(
          'Are you sure you want to withdraw the direct debit authorisation? Your private usage will no longer be deducted from your bank account.'
        )
      : t(
          'Are you sure you want to withdraw the authorisation? Any mobility cards will be terminated within 5 working days.'
        )

  return (
    <React.Fragment>
      <Button
        id={`revoke-button-${mandateId}`}
        className={Styles.button}
        variant="secondary"
        size={isTabletOrMobileScreen ? 'extraSmall' : 'small'}
        loading={isLoading}
        onClick={(): void => setShow(true)}
      >
        {t('Revoke mandate')}
      </Button>

      {show && (
        <ConfirmModal
          content={confirmMessage}
          cancelContent={t('Cancel')}
          onCancel={(): void => setShow(false)}
          confirmButtonProps={{
            onClick: revoke,
            content: t('Continue'),
            loading: isLoading,
          }}
        />
      )}
    </React.Fragment>
  )
}
