import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { IOwnershipEnum, useGetActiveAndFutureVehiclesQuery } from '../../../Lib/graphql'
import { AlertModal } from '../../AlertModal'
import { Button, Icon } from '../../index'
import { FuelTypeFormModal } from '../FuelTypeFormModal'
import { TVehicles } from '../FuelTypeFormModal/interfaces'
import Styles from './styles.module.scss'

export const FuelTypeAlertModal = (): JSX.Element | null => {
  const { t } = useTranslation()

  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [showForm, setShowForm] = useState<boolean>(false)
  const [becameEmployee, setBecameEmployee] = useState<boolean>(false)

  const { data } = useGetActiveAndFutureVehiclesQuery()
  const currentVehicles = data?.me?.current
  const futureVehicles = data?.me?.future

  useEffect(() => {
    if (data?.me?.becameEmployee) setBecameEmployee(true)
  }, [data?.me?.becameEmployee])

  // Collection of personal cars without specified fuel type
  const vehicles: TVehicles = useMemo(() => {
    if (!currentVehicles || !futureVehicles) return []

    const allVehicles = [...currentVehicles, ...futureVehicles]
    return allVehicles.reduce<TVehicles>((array, vehicle) => {
      const vehicleExist = array.some((item) => item.id === vehicle.id)

      if (vehicleExist) return array

      const requiresFuelType =
        vehicle.modality.modalityOwnership?.value === IOwnershipEnum.PERSONAL && !vehicle.vehicleInformation?.fuelType

      if (requiresFuelType) array.push(vehicle)

      return array
    }, [])
  }, [currentVehicles, futureVehicles])

  const handleClose = (): void => {
    setShowAlert(false)
    setShowForm(false)
  }

  const handleOpenForm = (): void => {
    setShowAlert(false)
    setShowForm(true)
  }

  useEffect(() => {
    setShowAlert(vehicles.length > 0)
  }, [vehicles.length])

  if (vehicles.length === 0) return null

  return (
    <React.Fragment>
      <AlertModal
        id="fuel-type-modal"
        title={t('Fuel type of your private vehicle')}
        description={t(
          "The legislation on work-related mobility of persons requires employers to report on their personnel's CO2 emissions. To calculate this amount, please select the fuel type of your private vehicle."
        )}
        icon={<Icon className={Styles.icon} width={97} height={97} name="leaves" />}
        show={showAlert}
        {...(becameEmployee && {
          onClose: (): void => {
            setShowAlert(false)
          },
        })}
      >
        <div className={Styles.buttons}>
          <Button id="select" size="big" onClick={handleOpenForm}>
            {t('Select fuel type')}
          </Button>
        </div>
      </AlertModal>

      {showForm && <FuelTypeFormModal vehicles={vehicles} onClose={(): void => handleClose()} />}
    </React.Fragment>
  )
}
