import React from 'react'

import { IProps } from './interfaces'
import { Invoices } from './Invoices'
import { Mandates } from './Mandates'

/*
    Twikey is the new way of handling transactions.
 */
const Twikey: React.FC<IProps> = ({ invoices, contracts, canRequestContract }): JSX.Element => {
  return (
    <div>
      {invoices.length > 0 && <Invoices invoices={invoices} />}
      <Mandates mandates={contracts} canRequestContract={canRequestContract} />
    </div>
  )
}

export default Twikey
