import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useTranslationDecorator } from '../../Hooks/useTranslationDecorator'
import { ROUTE_NAMES } from '../../Layouts/Authorized/interfaces'
import { routes } from '../../Layouts/Authorized/routes'
import { formatDateString } from '../../Lib/Formatters/DateFormatter'
import { saveSelectedSectionsInStorage } from '../../Pages/Expenses/Overview/Components/ExpensesTopBar/FilterModal/helper'
import { TSelectedSections } from '../../Pages/Expenses/Overview/Components/ExpensesTopBar/FilterModal/interface'
import {
  getFilterOptions,
  getMobilityEventsQueryArgumentsFromStorage,
  saveFilterOptions,
  saveMobilityEventsQueryArguments,
} from './helpers'
import { TFilterEvents, TFilterSearchParamsHook, TMobilityEventsQueryArguments } from './interfaces'

export const DATE_FORMAT_MOBILITY_EVENTS_QUERY = 'yyyy-MM-dd'

export const useFilterHelper = (): TFilterSearchParamsHook => {
  const navigate = useNavigate()
  const { locale } = useTranslationDecorator()
  const location = useLocation()

  // TODO: this function name is not covering the workings.
  //       this func will return the query args which are stored in the session storage.
  const getMobilityEventsQueryArguments = (): TMobilityEventsQueryArguments => {
    const parsedValue = getMobilityEventsQueryArgumentsFromStorage()

    return {
      offset: parsedValue?.offset || 0,
      limit: parsedValue?.limit || 20,
      happenedBetween: parsedValue?.happenedBetween,
      subCategories: parsedValue?.subCategories || [],
      modalities: parsedValue?.modalities || [],
      travelPurposes: parsedValue?.travelPurposes || [],
      filterByStatuses: parsedValue?.filterByStatuses || [],
    }
  }

  const mapFilterOptionsToQueryArguments = useCallback(
    (filterOptions: TFilterEvents): TMobilityEventsQueryArguments => {
      const {
        dates: { period },
        ...rest
      } = filterOptions

      return {
        offset: 0,
        limit: 20,
        happenedBetween: period
          ? {
              from: formatDateString(period.from, DATE_FORMAT_MOBILITY_EVENTS_QUERY, locale),
              to: formatDateString(period.to, DATE_FORMAT_MOBILITY_EVENTS_QUERY, locale),
            }
          : undefined,
        ...rest,
      }
    },
    [locale]
  )

  const navigateAndStoreFilterOptions = useCallback(
    (filterOptions: TFilterEvents, selectedSections?: TSelectedSections): void => {
      saveFilterOptions(filterOptions)

      if (selectedSections) saveSelectedSectionsInStorage(selectedSections)

      navigate(routes[ROUTE_NAMES.MOBILITY_EVENTS], { state: { filterOptions, selectedSections } })

      const mappedArguments = mapFilterOptionsToQueryArguments(filterOptions)

      saveMobilityEventsQueryArguments(mappedArguments)
    },
    [mapFilterOptionsToQueryArguments, navigate]
  )

  const filterOptions: TFilterEvents | null = useMemo(() => {
    const filterOptionsInLocationState = location.state?.filterOptions
    if (filterOptionsInLocationState) return filterOptionsInLocationState

    return getFilterOptions()
  }, [location.state?.filterOptions])

  const selectedSections: TSelectedSections | null = useMemo(() => {
    return location.state?.selectedSections || null
  }, [location.state?.selectedSections])

  return {
    getMobilityEventsQueryArguments,
    navigateAndStoreFilterOptions,
    filterOptions,
    selectedSections,
  }
}
