import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { Breadcrumbs } from '../../../../../../Components/Breadcrumbs'
import { SidebarCollapsedContext } from '../../../../../../Providers'
import { BreadcrumbsContext } from '../../../../../../Providers/BreadcrumbsProvider'
import { getPagesBasedOnPath } from './helpers'
import Styles from './styles.module.scss'

export const Content: React.FC<{ children: React.ReactNode }> = ({ children }): JSX.Element => {
  const location = useLocation()
  const { sidebarCollapsed } = useContext(SidebarCollapsedContext)
  const { setPath, path: breadcrumbs } = useContext(BreadcrumbsContext)

  const [showCrumbs, setShowCrumb] = useState<boolean>(false)

  useEffect((): void => {
    if (location.pathname !== '/') {
      setPath(getPagesBasedOnPath(location.pathname))
      setShowCrumb(true)
    } else setShowCrumb(false)
  }, [location, setPath])

  const classNames: string[] = [Styles.content]
  if (sidebarCollapsed) classNames.push(Styles.collapsedContent)

  return (
    <div className={Styles.contentWrapper}>
      {showCrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <div className={classNames.join(' ')} role="main">
        {children}
      </div>
    </div>
  )
}
