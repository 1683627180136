import { format } from 'date-fns'

import { capitalizeString, localeForDateFn } from './helpers'
import { ICurrentMonthProps } from './interfaces'

export const formatMonth = (date: Date, options: ICurrentMonthProps): string => {
  const { locale, capitalize = false } = options
  const convertedLocale = localeForDateFn(locale)
  const formatted = format(date, 'MMMM', { locale: convertedLocale })
  return capitalize ? capitalizeString(formatted) : formatted.toLowerCase()
}

export const currentMonth = (options: ICurrentMonthProps): string => {
  return formatMonth(new Date(), options)
}
