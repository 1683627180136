import React, { lazy, Suspense } from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

// Needs to be Lazy loaded to prevent it from loading the Map if not needed
const MapComponent = lazy(() => import('./Components/MapComponent'))

export const Map: React.FC<IProps> = (props): JSX.Element => {
  // The current version of the map provider (TomTom) causes errors in Cypress. As a work-around we return a dummy component.
  // @ts-ignore
  if (window.Cypress || !import.meta.env.REACT_APP_TOMTOM_API_KEY) {
    return (
      <div
        className={`${Styles.map} ${Styles.dummyMap}`}
        onClick={(): void => {
          const { onMapClick, onMoveEnd, onMapZoom } = props
          if (onMapClick) onMapClick({ latitude: 0, longitude: 0 })
          if (onMoveEnd) onMoveEnd({ latitude: 0, longitude: 0 })
          if (onMapZoom) onMapZoom(12)
        }}
        data-testid="dummy-tt-map"
      >
        MAP
      </div>
    )
  }

  return (
    <Suspense>
      <MapComponent {...props} />
    </Suspense>
  )
}
