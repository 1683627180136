import React from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { Button, Typography } from '../../../../../Components'
import { useMutationHelper } from '../../../../../Hooks/useMutationHelper'
import {
  GetBillingDataDocument,
  IGetBillingDataQuery,
  useCreateDirectDebitMandateMutation,
} from '../../../../../Lib/graphql'
import Styles from './styles.module.scss'

export const CreateDirectDebitMandate = (): JSX.Element => {
  const { t } = useTranslation()
  const { toastify } = useMutationHelper()
  const [createDirectDebitMandateMutation, { loading }] = useCreateDirectDebitMandateMutation()

  const requestDirectDebitMandate = (): void => {
    createDirectDebitMandateMutation({
      update: (cache, { data: responseData }) => {
        const create = responseData?.directDebitMandates?.create
        const newDirectDebitMandate = create?.directDebitMandate
        if (create?.result.error || !newDirectDebitMandate) return
        const isSplitBilling = false

        const query = cache.readQuery<IGetBillingDataQuery>({
          query: GetBillingDataDocument,
          variables: {
            isSplitBilling,
          },
        })

        if (!query) return

        const directDebitMandatesInCache = query.me?.eCurringMandates || []

        const updatedQueryData = {
          me: {
            ...query?.me,
            eCurringMandates: [newDirectDebitMandate, ...directDebitMandatesInCache],
          },
        }

        cache.writeQuery({
          query: GetBillingDataDocument,
          variables: {
            isSplitBilling,
          },
          data: updatedQueryData,
        })
      },
    })
      .then((response): void => {
        toastify(response.data?.directDebitMandates?.create?.result)
      })
      .catch((error): void => {
        toast.error(t('We encountered an error during submit, please try again later...'), { autoClose: false })
        console.error({ error })
      })
  }

  return (
    <div data-testid="create-local-mandate" className={Styles.createDirectDebitMandate}>
      <Typography className={Styles.title} variant="body-2">
        {t('Direct debit mandate')}
      </Typography>

      <Button
        id="create-direct-debit-mandate-button"
        className={Styles.button}
        variant="primary"
        onClick={requestDirectDebitMandate}
        loading={loading}
      >
        {t('Agree')}
      </Button>

      <Typography className={Styles.subInfo} variant="body-2">
        {t(
          "To order your mobility card, your authorisation is required for the collection of user fees not reimbursed by your employer. For the collection of these fees we work together with eCurring. By clicking on 'I agree' you give us permission to share your data with eCurring."
        )}
      </Typography>
    </div>
  )
}
