import React from 'react'
import Modal from 'react-modal'

import { Icon } from '../Icon'
import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const AlertModal: React.FC<IProps> = ({
  id,
  show,
  title,
  description,
  icon,
  onClose,
  children,
}): JSX.Element => {
  return (
    <Modal
      id={id}
      className={Styles.alertModal}
      isOpen={show}
      {...(onClose && {
        onRequestClose: (event): void => {
          event.stopPropagation()
          onClose()
        },
      })}
    >
      {onClose && (
        <div className="ReactModal__Overlay__CloseButton">
          <Icon
            id="close-button-icon"
            name="cross"
            onClick={(event): void => {
              event.stopPropagation()
              onClose()
            }}
          />
        </div>
      )}
      <div className={Styles.content}>
        {icon}
        <div className={Styles.textSection}>
          <Typography variant="h1-large" element="h3" className={Styles.title}>
            {title}
          </Typography>

          <Typography variant="body-2" className={Styles.description}>
            {description}
          </Typography>
        </div>

        {children}
      </div>
    </Modal>
  )
}
