import React, { useState } from 'react'
import { useMatch } from 'react-router-dom'

import { useTranslationDecorator } from '../../Hooks/useTranslationDecorator'
import { ROUTE_NAMES } from '../../Layouts/Authorized/interfaces'
import { routes } from '../../Layouts/Authorized/routes'
import { Button } from '../FormComponents'
import { ModalContainer } from '../ModalContainer'
import { currentVersion, fetchReleaseNotes, LAST_OPENED_APP_VERSION_KEY } from './helpers'
import Styles from './styles.module.scss'

export const FeatureReleaseContent: React.FC = (): JSX.Element | null => {
  const { t, locale } = useTranslationDecorator()
  const [show, setShow] = useState<boolean>(false)
  const [releaseNoteContent, setReleaseNoteContent] = useState<string>('')
  const isWelcomeRoute = useMatch(routes[ROUTE_NAMES.WELCOME])
  const lastSeenVersion = localStorage.getItem(LAST_OPENED_APP_VERSION_KEY) || '0'

  if (!isWelcomeRoute && currentVersion > lastSeenVersion) {
    fetchReleaseNotes(locale)
      .then((releaseNotes) => {
        const hasReleaseNotes = !!releaseNotes

        if (hasReleaseNotes) {
          setReleaseNoteContent(releaseNotes)
          setShow(true)
        }
      })
      .finally(() => {
        localStorage.setItem(LAST_OPENED_APP_VERSION_KEY, currentVersion)
      })
      .catch(() => {})
  }

  const handleCloseClick = (): void => {
    setShow(false)
  }

  if (!show || !releaseNoteContent) return null

  return (
    <ModalContainer title={t('Release notes')} className={Styles.modalContainer} onClose={handleCloseClick} show>
      <div data-testid="release-notes-modal">
        <p className={Styles.container} dangerouslySetInnerHTML={{ __html: releaseNoteContent }} />

        <Button id="release-notes-close-button" variant="primary" onClick={handleCloseClick} fullWidth>
          {t('Close')}
        </Button>
      </div>
    </ModalContainer>
  )
}
