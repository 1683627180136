import React from 'react'

import { Button } from '../../../FormComponents'
import { Typography } from '../../../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ContentSection: React.FC<IProps> = ({
  id,
  title,
  noPadding = false,
  children,
  className,
  buttonProps,
}): JSX.Element => {
  const hasTitleOrButton = !!title || !!buttonProps

  return (
    <section
      {...(id ? { id } : {})}
      className={`${Styles.contentSection} ${noPadding ? Styles.noPadding : ''} ${className || ''}`}
    >
      {hasTitleOrButton && (
        <div className={Styles.wrapper}>
          {title && (
            <Typography variant="h1" element="h3" className={Styles.title}>
              {title}
            </Typography>
          )}
          {buttonProps && (
            <Button
              id="content-section-button"
              className={Styles.button}
              variant="secondary"
              onClick={buttonProps.onClick}
            >
              {buttonProps.name}
            </Button>
          )}
        </div>
      )}

      {children}
    </section>
  )
}
