// @ts-ignore as there is no type definition file anymore.
import { SingleValue, StylesConfig } from 'react-select'

import { IOption } from './interfaces'

export const styles: StylesConfig<SingleValue<IOption>, false> = {
  control: (provided) => ({
    ...provided,
    minHeight: 40,
    zIndex: 1,
  }),
  input: (provided) => ({
    ...provided,
    'input[type=text]': {
      height: 'auto',
      margin: 0,
    },
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 2,
  }),
}
