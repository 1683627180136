import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import { CurrentUserContext } from '../../../../Providers'
import { Icon } from '../../../Icon'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ProfileToggle: React.FC<IProps> = ({ collapsed = true, loading = false, onClick }): JSX.Element => {
  const { t } = useTranslation()
  const { firstName } = useContext(CurrentUserContext)

  return (
    /* eslint-disable-next-line reisbalans-rules/prevent-vanilla-html-elements */
    <button
      aria-label={collapsed ? t('Expand settings') : t('Collapse settings')}
      className={Styles.toggleContainer}
      onClick={onClick}
      type="button"
      data-testid="toggle-button"
      id="toggle-button"
    >
      {loading ? (
        <Skeleton width={80} style={{ backgroundColor: 'transparent' }} />
      ) : (
        <React.Fragment>
          <Icon name="user" className={Styles.icon} />
          {firstName ? <p className={Styles.firstName}>{firstName}</p> : ''}

          <div className={`${Styles.iconContainer} ${!collapsed ? Styles.flipped : ''}`}>
            <Icon name="chevron-down" />
          </div>
        </React.Fragment>
      )}
    </button>
  )
}
