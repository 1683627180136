import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

import { Icon } from '../../Icon'
import { IProps } from './interface'
import Styles from './styles.module.scss'

export const Link: React.FC<IProps> = ({
  children,
  className,
  displayExternalLinkIcon = true,
  href,
  ref: _ref, // Remove ref property from otherProps as it opens up a world of pain in typing
  rel,
  target,
  themeColor,
  withUnderline = true,
  loading = false,
  ariaLabel,
  ...otherProps
}): JSX.Element => {
  const classNames: string[] = [Styles.link]
  if (themeColor) classNames.push(Styles[themeColor])
  if (loading) classNames.push(Styles.loading)
  if (withUnderline) classNames.push(Styles.underline)
  if (className) classNames.push(className)

  // KISS: if it contains as column then it is either a port or protocol, so let us behold that as external.
  const isExternal = href && !!href.match(/:/)

  if (isExternal) {
    // We have an external link. Check if it's not a link to the legacy web app, if so, we do not need user-
    // facing indicators that it's an external link.
    const isLegacyLink =
      import.meta.env.REACT_APP_LEGACY_APP_URL && href && !!href.match(import.meta.env.REACT_APP_LEGACY_APP_URL)

    // If an explicit target was given, use it. If not, use _blank for external links, else self.
    const transformedLinkTarget = target || (!isLegacyLink ? '_blank' : '_self')

    // If the target is _blank, always use rel="noreferrer". For more info on this, see:
    //  https://html.spec.whatwg.org/multipage/links.html#link-type-noopener
    const transformedLinkRel = transformedLinkTarget === '_blank' ? 'noreferrer' : rel

    return (
      /* eslint-disable-next-line reisbalans-rules/prevent-vanilla-html-elements */
      <a
        className={classNames.join(' ')}
        href={href}
        rel={transformedLinkRel}
        target={transformedLinkTarget}
        aria-label={ariaLabel}
        {...otherProps}
        onClick={(event): void => event.stopPropagation()}
      >
        {children}
        {!isLegacyLink && displayExternalLinkIcon && <Icon name="external-link" className={Styles.externalLinkIcon} />}
      </a>
    )
  }

  if (!href) {
    // If we use a Link component for onPress only it does not have a href. In this case we don't want to
    // render the React Router <Link> component, but just an Anchor element. If you run into this case, you
    // might want to use a button instead of our Link component.

    return (
      /* eslint-disable-next-line reisbalans-rules/prevent-vanilla-html-elements */
      <a className={classNames.join(' ')} aria-label={ariaLabel} {...otherProps}>
        {children}
      </a>
    )
  }

  return (
    <ReactRouterLink className={classNames.join(' ')} to={href} aria-label={ariaLabel} {...otherProps}>
      {children}
    </ReactRouterLink>
  )
}
