import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../Components'
import { Typography } from '../../../Components/Typography'
import { AuthContext } from '../../../Providers'
import Styles from '../styles.module.scss'

export const InternalServerErrorPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const { loggedIn } = useContext(AuthContext)
  const navigate = useNavigate()

  const errorMessage = ((): string | null => {
    const queryParams = location.search
    const params = new URLSearchParams(queryParams)
    return params.get('error')
  })()

  return (
    <div className={Styles.container}>
      <div className={Styles.imgContainer} />
      <h1>{t('Server error')}</h1>
      <Typography variant="body-2" className={Styles.errorMessage}>
        <Trans>The server refuses to support the specified site.</Trans>{' '}
        {errorMessage && <Trans values={{ errorMessage }}>Error message: {{ errorMessage }}</Trans>}
      </Typography>

      {loggedIn && (
        <Button id="dashboard-button" variant="primary" onClick={(): void => navigate('/')}>
          {t('Go to my dashboard')}
        </Button>
      )}
    </div>
  )
}

export default InternalServerErrorPage
