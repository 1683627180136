import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { TGcsCoordinates } from '../../Hooks/useMap/interfaces'
import { useMutationHelper } from '../../Hooks/useMutationHelper'
import { useGetFavoritesWithFallbackQuery, useSetManualFavoriteCoordinatesMutation } from '../../Lib/graphql'
import { CurrentUserContext } from '../../Providers'
import { ConfirmModal } from '../ConfirmModal'
import { ConfirmFooter, Footer } from '../Modal/Components'
import { ModalContainer } from '../ModalContainer'
import { LocationAlert } from './Components/LocationAlert'
import { LocationFix } from './Components/LocationFix'
import { TLocation } from './interfaces'
import Styles from './styles.module.scss'

export const FixLocationModal = (): JSX.Element | null => {
  const { t } = useTranslation()
  const { toastify } = useMutationHelper()

  const { data, refetch } = useGetFavoritesWithFallbackQuery()
  const [mutation, { loading }] = useSetManualFavoriteCoordinatesMutation()

  const [favoriteLocation, setFavoriteLocation] = useState<TLocation>()
  const [showModal, setShowModal] = useState<boolean>(true)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [markerCoordinates, setMarkerCoordinates] = useState<TGcsCoordinates>()

  const [showAlert, setShowAlert] = useState<boolean>(true)
  const [showLocationFix, setShowLocationFix] = useState<boolean>(false)

  const [becameEmployee, setBecameEmployee] = useState<boolean>(false)

  useEffect((): void => {
    if (!data || !data.me?.favorites?.length) {
      setFavoriteLocation(undefined)
      return
    }

    const favorite = data.me.favorites[0]
    setFavoriteLocation(favorite)
  }, [data])

  useEffect(() => {
    if (data?.me?.becameEmployee) setBecameEmployee(true)
  }, [data?.me?.becameEmployee])

  const handleSave = (): void => {
    if (!favoriteLocation || !markerCoordinates) return

    mutation({
      variables: {
        id: favoriteLocation.id,
        longitude: markerCoordinates.longitude,
        latitude: markerCoordinates.latitude,
      },
    })
      .then((response) => {
        refetch()
        toastify(response.data?.favorites?.setManualCoordinates?.result, { onSuccess: () => setShowModal(false) })
      })
      .catch((error) => {
        toast.error(t('We encountered an error during submit, please try again later...'), { autoClose: false })
        console.error(error)
      })
  }

  const { setVisibility } = useContext(CurrentUserContext)

  const handleAlertOnClick = (): void => {
    setShowAlert(false)
    setShowLocationFix(true)
  }

  const renderFooter = (): JSX.Element | undefined => {
    if (showLocationFix) {
      return (
        <Footer noBorder className={Styles.confirmFooterWrapper}>
          <ConfirmFooter
            confirmLabel={t('Save location')}
            onConfirm={(): void => setShowConfirmModal(true)}
            disableConfirm={!markerCoordinates}
            showCancelOption={false}
          />
        </Footer>
      )
    }
  }

  useEffect(() => {
    setVisibility((prev) => ({ ...prev, fixLocation: favoriteLocation ? showModal : false }))
  }, [favoriteLocation, showModal, setVisibility])

  if (!favoriteLocation) return null

  const addressType = favoriteLocation.type

  return (
    <ModalContainer
      id="select-new-location-modal"
      className={`${Styles.locationModal} ${showLocationFix ? Styles.fullHeight : ''}`}
      {...(becameEmployee && {
        onClose: (): void => {
          setShowModal(false)
        },
      })}
      show={showModal}
      withPadding={false}
    >
      <div data-testid="alert" className={`${Styles.content} ${showAlert ? Styles.show : ''}`}>
        <LocationAlert type={addressType} onClick={handleAlertOnClick} />
      </div>

      <div className={`${Styles.content} ${showLocationFix ? Styles.show : ''}`}>
        {showLocationFix && <LocationFix type={addressType} onChange={setMarkerCoordinates} />}
      </div>

      {showConfirmModal && (
        <ConfirmModal
          content={t('Save')}
          description={t('Are you sure you want to save the location selected?')}
          cancelContent={t('Cancel')}
          onCancel={(): void => setShowConfirmModal(false)}
          confirmButtonProps={{
            onClick: handleSave,
            content: t('Save location'),
            loading,
          }}
        />
      )}

      {renderFooter()}
    </ModalContainer>
  )
}
