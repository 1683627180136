/**
 * Be aware! This is the dumb component of the `TaskSidebar`. While we generally add those in the same `index.tsx` as
 * the connected component, this is intentionally added as separate file. Since `TaskSidebar/__mocks__/index.tsx` uses
 * the `TaskSidebarComponent` to prevent it from making a backend call it needs to be included from another file then
 * the file where the connected `TaskSidebar` component is defined to prevent infinite include loops.
 */
import React, { useMemo, useRef, useState } from 'react'

import { useOnClickOutside } from '../../../../Hooks/useClickedOutside/useOnClickOutside'
import { ITaskEnum } from '../../../../Lib/graphql'
import { TaskListComponent } from '../../../TaskList'
import { TaskToggle } from '../TaskToggle'
import { IProps } from './interface'
import Styles from './styles.module.scss'

export const TaskSidebarComponent: React.FC<IProps> = ({
  error,
  loading,
  confirmableMobilityEventsCount,
  tasks,
}): JSX.Element | null => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

  const toggleSidebarOpen = (): void => {
    setSidebarOpen((previousState) => !previousState)
  }

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside({ handler: (): void => setSidebarOpen(false), ref })

  const totalTasks = useMemo((): number => {
    const list: ITaskEnum[] = []

    tasks?.forEach((task) => {
      const mobilityEventType = ITaskEnum.MOBILITY_EVENTS_WEEK
      if (list.includes(mobilityEventType) && task.type === mobilityEventType) return
      list.push(task.type)
    })

    return list.length
  }, [tasks])

  if (error) return null

  return (
    <React.Fragment>
      <div className={Styles.toggleWrapper} ref={ref}>
        <TaskToggle collapsed={!sidebarOpen} loading={loading} numTasks={totalTasks} onClick={toggleSidebarOpen} />
      </div>
      <div className={`${Styles.taskListSidebar} ${sidebarOpen ? Styles.open : ''}`}>
        <div className={Styles.taskListContainer}>
          <TaskListComponent
            displayTitle
            confirmableMobilityEventsCount={confirmableMobilityEventsCount}
            loading={loading}
            tasks={tasks}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
