import { useApolloClient } from '@apollo/client'
import React, { useState } from 'react'
import { SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'

import { GetPlaceDetailsDocument, IGetPlaceDetailsQuery, useGetFavoriteLocationsQuery } from '../../../Lib/graphql'
import { MaybeRenderWithLabelHOC } from '../Lib/MaybeRenderWithLabelHOC'
import { MenuList } from './Components/MenuList'
import { NoOptionsMessage } from './Components/NoOptionMessage'
import { OptionComponent } from './Components/Option'
import { SingleValueComponent } from './Components/SingleValue'
import { IDataProps, IOption, IOptionValue, IProps } from './interfaces'
import { styles } from './styles'
import { useLocationSelector } from './useLocationSelector'

export const LocationSelectComponent: React.FC<IProps> = ({
  disabled,
  id,
  label,
  loading,
  loadOptions,
  onChange,
  onMenuOpen,
  onMenuClose,
  placeholder,
  selected,
  isClearable = true,
  ...otherProps
}): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>(selected?.label || '')

  const handleInputChange = (newValue: string): void => {
    setInputValue(newValue)
  }

  const handleInputFocus = (): void => {
    setInputValue(selected?.label || '')
  }

  const handleSelectionChange = (option: { value: IOptionValue; label: string } | null): void => {
    if (!option) setInputValue('')

    onChange(option)
  }

  const handleInputBlur = (): void => {
    if (!document.activeElement) return

    // This timeout is needed, otherwise the blur is not triggered.
    setTimeout(() => {
      const element = document.activeElement as HTMLElement

      element.blur()
    }, 10)
  }

  return (
    <AsyncSelect<SingleValue<IOption>, false>
      {...otherProps}
      isLoading={loading}
      aria-label={label}
      inputId={id}
      isDisabled={disabled}
      styles={styles}
      placeholder={placeholder}
      loadOptions={loadOptions}
      onChange={handleSelectionChange}
      classNamePrefix="location-select"
      value={selected}
      inputValue={inputValue}
      isClearable={isClearable}
      components={{
        Option: OptionComponent,
        NoOptionsMessage,
        MenuList,
        DropdownIndicator: null,
        SingleValue: SingleValueComponent,
      }}
      onInputChange={handleInputChange}
      onFocus={handleInputFocus}
      onBlur={handleInputBlur}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      blurInputOnSelect
      isMulti={false}
      backspaceRemovesValue={false}
    />
  )
}

export const LocationSelectWrapped: React.FC<IProps> = MaybeRenderWithLabelHOC(LocationSelectComponent)

export const LocationSelect: React.FC<IDataProps> = (props): JSX.Element => {
  const { onChange, withFavorites = false } = props
  const { loadSuggestions, loading } = useLocationSelector()

  const { data } = useGetFavoriteLocationsQuery({ skip: !withFavorites })
  const favorites = data?.me?.favorites || []
  const [errors, setErrors] = useState<string[]>([])
  const apolloClient = useApolloClient()

  const handleNonFavoriteOptionSelect = async (option: SingleValue<IOption>): Promise<void> => {
    if (!option) return

    const result = await apolloClient.query<IGetPlaceDetailsQuery>({
      query: GetPlaceDetailsDocument,
      variables: { placeId: option.value.id },
      fetchPolicy: 'no-cache',
    })

    setErrors(result.data?.places?.details?.validationErrors.flatMap((item) => item.fullMessages) || [])

    const coordinates = result.data?.places?.details?.coordinates
    const locationId = result.data?.places?.details?.locationId

    onChange({
      ...option,
      value: { ...option.value, locationId, coordinates },
    })
  }

  const handleOnChange = async (option: SingleValue<IOption>): Promise<void> => {
    if (!option) {
      onChange(null)
      return
    }

    // If the option is one of our favorites, we have all the data we need, forward it
    if (option.value.placeType === 'favorite') onChange(option)
    else handleNonFavoriteOptionSelect(option)
  }

  const favoriteLocations: SingleValue<IOption>[] = favorites.map((favorite) => ({
    label: favorite.label,
    value: {
      locationId: favorite.locationId || undefined,
      coordinates: favorite.coordinates,
      placeType: 'favorite',
      id: favorite.id,
      location: favorite.location,
    },
  }))

  return (
    <LocationSelectWrapped
      {...props}
      defaultOptions={favoriteLocations}
      loading={loading}
      loadOptions={loadSuggestions}
      onChange={handleOnChange}
      errors={errors}
    />
  )
}
