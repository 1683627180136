import React, { FormEvent } from 'react'
import { Trans } from 'react-i18next'

import { IProps } from './interfaces'

export const Form: React.FC<IProps> = ({ children, errors = {}, id, onSubmit, ...otherProps }): JSX.Element => {
  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault()
    onSubmit(event)
  }

  const maybeRenderErrors = (): JSX.Element | null => {
    if (!Object.keys(errors).length) return null
    return (
      <div id="errorExplanation">
        <h2>
          <Trans>The form contains one or more errors.</Trans>
        </h2>
      </div>
    )
  }

  return (
    /* eslint-disable-next-line reisbalans-rules/prevent-vanilla-html-elements */
    <form {...otherProps} onSubmit={handleSubmit} id={id}>
      {maybeRenderErrors()}
      {children}
    </form>
  )
}
