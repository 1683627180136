import React from 'react'

import { Icon } from '../Icon'
import LottieAnimation from '../LottieAnimation'
import { PillButton } from '../PillButton'
import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const EmptyState: React.FC<IProps> = ({
  title,
  description,
  icon,
  iconColor,
  buttonLabel,
  lottieAnimation,
  onButtonClick,
  ...otherProps
}): JSX.Element => {
  return (
    <div className={Styles.emptyState} data-testid="emptyState" {...otherProps}>
      {icon && (
        <Icon name={icon} width={40} height={40} className={Styles.icon} {...(iconColor ? { color: iconColor } : {})} />
      )}

      {lottieAnimation && (
        <div className={Styles.lottie}>
          <LottieAnimation animationData={lottieAnimation} height={60} width={60} />
        </div>
      )}

      {title && (
        <Typography variant="h1-large" className={Styles.title}>
          {title}
        </Typography>
      )}

      <Typography variant="body-1" className={Styles.description}>
        {description}
      </Typography>

      {buttonLabel && onButtonClick && (
        <PillButton className={Styles.button} label={buttonLabel} onClick={onButtonClick} />
      )}
    </div>
  )
}
