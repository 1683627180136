import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Image from '../../../Assets/Gif/Fueltype-animation.gif'
import { useMutationHelper } from '../../../Hooks/useMutationHelper'
import { useUpdatePersonalVehicleMutation } from '../../../Lib/graphql'
import { MediaQueriesContext } from '../../../Providers'
import { AnimatedSection } from '../../AnimatedSection'
import { ConfirmModal } from '../../ConfirmModal'
import { Button } from '../../index'
import { Modal } from '../../Modal'
import { Footer } from '../../Modal/Components'
import { FuelTypeInputSection } from './Components/FuelTypeInputSection'
import { IProps, TFuelTypeInput, TVehicles } from './interfaces'
import Styles from './styles.module.scss'

export const FuelTypeFormModal: React.FC<IProps> = ({ vehicles }): JSX.Element | null => {
  const { t } = useTranslation()
  const { isSMScreen } = useContext(MediaQueriesContext)
  const [formData, setFormData] = useState<TFuelTypeInput[]>([])
  const [showConfirm, setShowConfirm] = useState<boolean>(false)

  const mapVehicleToFormEntry = useCallback(
    (vehicle: TVehicles[0]): TFuelTypeInput => {
      return {
        id: vehicle.id,
        label: vehicle.humanized || vehicle.name || t('Unknown'),
        icon: vehicle.icon?.name,
        fuelType: null,
        licensePlate: vehicle.licensePlate || null,
        startsOn: vehicle.startsOn,
      }
    },
    [t]
  )

  useEffect(() => {
    if (vehicles.length === 0) return

    setFormData(vehicles.map(mapVehicleToFormEntry))
  }, [mapVehicleToFormEntry, vehicles])

  const handleChange = (newValue: TFuelTypeInput): void => {
    setFormData((prevState) => {
      return prevState.map((item) => {
        if (item.id === newValue.id) return newValue

        return item
      })
    })
  }

  const canSubmit = formData.every((entry) => !!entry.fuelType)
  const { toastify } = useMutationHelper()

  const [update, { loading }] = useUpdatePersonalVehicleMutation()

  const handleSubmit = (): void => {
    setShowConfirm(false)
    if (!canSubmit) return

    formData.forEach(({ id, licensePlate, fuelType, startsOn }) => {
      update({
        variables: {
          input: {
            vehicleId: id,
            startsOn,
            fuelType,
            ...(licensePlate && { licensePlate }),
          },
        },
      })
        .then((response) => {
          const result = response.data?.vehicle?.update?.result
          toastify(result)
          if (result?.success) setFormData((prevState) => prevState.filter((item) => item.id !== id))
        })
        .catch(console.error)
    })
  }

  if (formData.length === 0) return null

  return (
    <Modal id="fuel-type-form-modal" title={t('Fuel')} hideHeader>
      <div className={Styles.content}>
        {isSMScreen && (
          <AnimatedSection
            title={t('Select your vehicle’s fuel type ')}
            description={t(
              'The fuel type can be selected below. If you are unsure, please enter your licence plate and the relevant fuel type will be entered automatically.'
            )}
            image={Image}
          />
        )}

        <div data-testid="sections" className={Styles.sections}>
          {formData.map((vehicle) => (
            <FuelTypeInputSection key={vehicle.id} value={vehicle} onChange={handleChange} />
          ))}
        </div>
      </div>

      <Footer>
        <div className={Styles.buttons}>
          <Button
            id="save"
            size="big"
            loading={loading}
            disabled={!canSubmit}
            onClick={(): void => setShowConfirm(true)}
          >
            {t('Save')}
          </Button>

          {showConfirm && (
            <ConfirmModal
              content={t('Save')}
              description={t('Are you sure you want to save the selected fuel type?')}
              cancelContent={t('Cancel')}
              confirmButtonProps={{ content: t('Save selection'), onClick: handleSubmit }}
              onCancel={(): void => setShowConfirm(false)}
            />
          )}
        </div>
      </Footer>
    </Modal>
  )
}
