import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ListItemButton } from '../../../../Components/ListItemButton'
import { ListWrapper } from '../../../../Components/ListWrapper'
import { ROUTE_NAMES } from '../../../../Layouts/Authorized/interfaces'
import { routes } from '../../../../Layouts/Authorized/routes'
import { Mandate } from '../../Components/Mandate'
import { IProps as IMandateProps } from '../../Components/Mandate/interfaces'
import { Title } from '../../Components/Title'
import { mapECurringMandate } from '../../helpers'
import { CreateDirectDebitMandate } from './CreateDirectDebitMandate'
import { ExternalAgreement } from './ExternalAgreement'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Mandates: React.FC<IProps> = ({ mandates, eMandateRequired }): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const mappedMandates: IMandateProps[] = mandates.map(mapECurringMandate)
  const currentDirectDebitMandate = mandates.find(({ status, isRevoked }) => {
    // Based on backend logic:
    // A mandate is ACTIVE if it is not revoked OR status is equal to null.
    return !isRevoked || status === null
  })

  const acceptanceUrl = currentDirectDebitMandate?.acceptanceUrl
  const hide = !eMandateRequired || (!!currentDirectDebitMandate && !acceptanceUrl)

  return (
    <div data-testid="mandates-container" className={Styles.container}>
      <Title title={t('eMandates')} />

      <ListWrapper>
        {!hide && (
          <React.Fragment>
            {((): JSX.Element | null => {
              //  First step is to create direct debit mandate
              if (!currentDirectDebitMandate) return <CreateDirectDebitMandate />

              // Second step is to finalize the process on the eCurring page
              if (acceptanceUrl) return <ExternalAgreement url={acceptanceUrl} />

              return null
            })()}
          </React.Fragment>
        )}

        {mappedMandates.length > 0 && (
          <ListWrapper id="mandates">
            {mappedMandates.slice(0, 3).map((mandate) => {
              return <Mandate key={`mandate-${mandate.mandateId}`} {...mandate} />
            })}
            {mappedMandates.length > 3 && (
              <ListItemButton
                label={t('See all mandates')}
                onClick={(): void => navigate(routes[ROUTE_NAMES.PAYMENTS_MANDATES])}
              />
            )}
          </ListWrapper>
        )}
      </ListWrapper>
    </div>
  )
}
