import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import { Icon } from '../../Icon'
import { Typography } from '../../Typography'
import { MaybeRenderWithLabelHOC } from '../Lib/MaybeRenderWithLabelHOC'
import { MaybeWithMaxLengthHint } from './Components/MaybeWithMaxLengthHint'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

// eslint-disable-next-line sonarjs/cognitive-complexity
export const InputComponent: React.FC<IProps> = (props): JSX.Element => {
  const {
    autocomplete = 'on',
    className,
    disabled,
    errors,
    inline,
    label,
    onChange,
    onBlur,
    required,
    loading = false,
    type = 'text',
    value,
    iconName,
    readOnly,
    wrapperClassName,
    clearable = true,
    maxLength,
    isValid,
    ...otherProps
  } = props
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [input, setInput] = useState<string>(value !== undefined ? String(value) : '')
  const inputRef = useRef<HTMLInputElement>(null)
  const hasValue = !!value || !!inputRef.current?.value
  const clearableSupportedForInputType = type !== 'radio' && type !== 'hidden'
  const canClearInput =
    clearable && !disabled && hasValue && clearableSupportedForInputType && !readOnly && !maxLength && !isValid
  const canRevealInput = type === 'password' && hasValue && !maxLength && !isValid

  const cssClasses = ((): string => {
    const classNames: string[] = [Styles.input]
    if (className) classNames.push(className)
    if (inline) classNames.push(Styles.inline)
    if (disabled) classNames.push(Styles.disabled)
    if (errors?.length) classNames.push(Styles.hasError)
    if (iconName) classNames.push(Styles.paddingLeft)
    if (canClearInput || canRevealInput || isValid) classNames.push(Styles.iconPaddingEnd)
    if (canClearInput && canRevealInput) classNames.push(Styles.iconsPaddingEnd)
    if (maxLength) classNames.push(Styles.maxLengthPaddingEnd)

    return classNames.join(' ')
  })()

  const preIconClassNames = ((): string => {
    const classNames: Array<string> = [Styles.icon]

    if (disabled) classNames.push(Styles.disabled)

    return classNames.join(' ')
  })()

  const showPasswordIconClassNames = ((): string => {
    const classNames: Array<string> = [Styles.iconRevealPassword]

    if (canClearInput) classNames.push(Styles.moveToLeft)

    return classNames.join(' ')
  })()

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newValue = event.currentTarget.value
    setInput(newValue)
    if (onChange) onChange(newValue)
  }

  const handleOnBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onBlur) onBlur(event.currentTarget.value)
  }

  const resetInputField = (): void => {
    if (inputRef.current) inputRef.current.value = ''
    if (onChange) onChange('')
  }

  useEffect(() => {
    if (value || value === '') setInput(String(value))
  }, [value])

  if (loading) return <Skeleton className={cssClasses} />

  const wrapperClassNames = ((): string => {
    const classNames: string[] = [Styles.wrapper]

    if (wrapperClassName) classNames.push(wrapperClassName)

    return classNames.join(' ')
  })()

  return (
    <MaybeWithMaxLengthHint label={label} maxLength={maxLength}>
      <div className={wrapperClassNames}>
        {iconName && <Icon name={iconName} className={preIconClassNames} />}

        {/* eslint-disable-next-line reisbalans-rules/prevent-vanilla-html-elements */}
        <input
          {...otherProps}
          aria-label={otherProps['aria-label'] || label}
          autoComplete={autocomplete}
          className={cssClasses}
          disabled={disabled}
          readOnly={readOnly}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onMouseUp={(event): void => event.stopPropagation()}
          required={required}
          type={type === 'password' && showPassword ? 'text' : type}
          value={value || input}
          ref={inputRef}
          maxLength={maxLength?.length}
        />

        {isValid && <Icon className={Styles.isValid} name="icon-check-success" />}

        {canRevealInput && (
          <Icon
            name={showPassword ? 'eye-hide' : 'eye-show'}
            className={showPasswordIconClassNames}
            onClick={(): void => setShowPassword(!showPassword)}
          />
        )}

        {canClearInput && <Icon name="close-circle" onClick={resetInputField} className={Styles.resetIcon} />}

        {maxLength && (
          <Typography variant="body-3" data-testid="count" className={Styles.count}>
            {`${input.length}/${maxLength.length}`}
          </Typography>
        )}
      </div>
    </MaybeWithMaxLengthHint>
  )
}

export const Input = MaybeRenderWithLabelHOC(InputComponent)
