import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { ROUTE_NAMES } from '../../../../Layouts/Authorized/interfaces'
import { routes } from '../../../../Layouts/Authorized/routes'
import { useGetPermissionPerNavigationItemQuery } from '../../../../Lib/graphql'
import { AuthContext, CurrentUserContext, MediaQueriesContext } from '../../../../Providers'
import { Typography } from '../../../Typography'
import { listId } from './helpers'
import { MenuItem } from './MenuItem'
import { THoverType } from './MenuItem/interfaces'
import Styles from './styles.module.scss'

export const SettingsListComponent: React.FC = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logout } = useContext(AuthContext)
  const { isMDScreen } = useContext(MediaQueriesContext)
  const { firstName } = useContext(CurrentUserContext)
  const { data } = useGetPermissionPerNavigationItemQuery()
  const allowLoginByQrCode = isMDScreen && data?.me?.permissions?.allowLoginByQrCode

  const onHover = (event: React.MouseEvent<HTMLDivElement>, id: string, type: THoverType): void => {
    const child = document.getElementById(id)
    if (!child) return

    const childrenOfParent = document.getElementById(listId)?.children || []
    const indexOfChild = Array.from(childrenOfParent).indexOf(child)
    if (indexOfChild === 0) return

    const sibling = childrenOfParent[indexOfChild - 1]
    const item = sibling.children[0] as HTMLDivElement
    type === 'enter' ? item.classList.add(Styles.preHoverItem) : item.classList.remove(Styles.preHoverItem)
  }

  return (
    <div className={Styles.settingsListContainer}>
      <div id="settingsList" className={Styles.settingList}>
        {!isMDScreen && (
          <div className={Styles.titleWrapper}>
            <Typography variant="h1-large" element="span">
              {firstName}
            </Typography>
          </div>
        )}

        <div id={listId} className={Styles.menu}>
          <MenuItem
            id="details"
            data-testid="details"
            aria-label={t('Link to go the the profile information page.')}
            label={t('Personal Information')}
            icon="user"
            onClick={(): void => navigate(routes[ROUTE_NAMES.PROFILE_INFORMATION])}
            onHover={onHover}
          />

          <MenuItem
            id="privacy"
            data-testid="privacy"
            aria-label={t('Link to go to the privacy page.')}
            label={t('Privacy')}
            icon="shield"
            onClick={(): void => navigate(routes[ROUTE_NAMES.PRIVACY])}
            onHover={onHover}
          />

          <MenuItem
            id="settings"
            data-testid="settings"
            aria-label={t('Link to go to the profile settings page.')}
            label={t('Settings')}
            icon="settings"
            onClick={(): void => navigate(routes[ROUTE_NAMES.PROFILE_SETTINGS])}
            onHover={onHover}
          />

          {allowLoginByQrCode && (
            <MenuItem
              id="qr-code"
              data-testid="qr-code"
              aria-label={t('Link to go to the qr-code page.')}
              label={t('Login with QR Code')}
              icon="qr"
              onClick={(): void => navigate(routes[ROUTE_NAMES.PROFILE_QR])}
              onHover={onHover}
            />
          )}

          <MenuItem
            id="logout-link"
            data-testid="logout-link"
            aria-label={t('Link to log out.')}
            label={t('Logout')}
            icon="logout"
            onClick={logout}
            onHover={onHover}
          />
        </div>
      </div>
    </div>
  )
}
