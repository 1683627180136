import { TFunction } from 'i18next'

import { IFavoriteTypeEnum } from '../../../../Lib/graphql'
import { TLocationType } from '../../interfaces'

export const getTitle = (type: TLocationType, translate: TFunction): string => {
  const map: { [key: string]: string } = {
    [IFavoriteTypeEnum.HOME]: translate('Location home address not found'),
    [IFavoriteTypeEnum.WORK]: translate('Location work address not found'),
  }

  if (!type || !map[type]) return translate('Location {{ type }} not found', { type })

  return map[type]
}

export const getDescription = (type: TLocationType, translate: TFunction): string => {
  const map: { [key: string]: string } = {
    [IFavoriteTypeEnum.HOME]: translate('Home address'),
    [IFavoriteTypeEnum.WORK]: translate('Work address'),
  }

  const humanizedType = (type ? map[type] || type : translate('Address')).toLowerCase()

  return translate(
    'To calculate distances for your expense claims, we need the correct coordinates of your {{ type }}. We were unable to find this using the address provided. Please indicate the location of your {{ type }} on the map.',
    { type: humanizedType }
  )
}
