import React, { useState } from 'react'

import { Icon } from '../Icon'
// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import TypographyStyles from '../Typography/styles.module.scss'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Hint: React.FC<IProps> = ({ hint, children }): JSX.Element => {
  const [showHint, setShowHint] = useState<boolean>(false)

  return (
    <div className={Styles.hintContainer}>
      <div className={Styles.iconWrapper}>
        {children}

        <Icon
          className={Styles.icon}
          data-testid="hint-icon"
          name="info-circle"
          onClick={(event): void => {
            event.preventDefault()
            setShowHint((prev) => !prev)
          }}
        />
      </div>

      <div className={[Styles.accordionContent, ...(showHint ? [Styles.visible] : [])].join(' ')}>
        <div className={Styles.accordionInner}>
          <p className={[TypographyStyles['body-3'], Styles.hint].join(' ')}>{hint}</p>
        </div>
      </div>
    </div>
  )
}
