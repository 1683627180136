import React from 'react'

import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ErrorsBlock: React.FC<IProps> = ({ className, errors, withBottomMargin = false }): JSX.Element | null => {
  if (errors.length === 0) return null

  const classNames = ((): string => {
    const classes: string[] = [Styles.errorsBlock]
    if (className) classes.push(className)
    if (withBottomMargin) classes.push(Styles.bottomMargin)
    return classes.join(' ')
  })()

  return (
    <div id="validation-errors" data-testid="validation-errors" className={classNames}>
      {errors.map((error, index) => (
        <Typography key={`${index}-${error}`} variant="body-3" className={Styles.error}>
          {error}
        </Typography>
      ))}
    </div>
  )
}
