import { parseDate } from '../../Lib/Formatters/DateFormatter'
import { queryParamId } from '../Settlements/WhatBlock/helpers'
import { IProps as IInvoiceProps } from './Components/Invoice/interfaces'
import { IProps as IMandateProps } from './Components/Mandate/interfaces'
import { TDirectDebitInvoices, TDirectDebitMandates } from './interfaces'
import { TwikeyContracts, TwikeyInvoices } from './Twikey/interfaces'

export const mapECurringInvoice = (invoice: TDirectDebitInvoices[0]): IInvoiceProps => {
  const status: string[] = [invoice.externalReference]
  if (invoice.status?.humanized) status.push(`· ${invoice.status.humanized}`)
  const settlementId = invoice.settlement?.id
  return {
    date: parseDate(invoice.invoiceDate),
    label: status.join(' '),
    pdfUrl: invoice.pdfUrl,
    ...(settlementId && { xlsUrl: `/settlements?${queryParamId}=${settlementId}` }),
  }
}

export const mapTweakyInvoice = (invoice: TwikeyInvoices[0]): IInvoiceProps => {
  return {
    date: parseDate(invoice.invoiceDate),
    label: invoice.externalReference,
    pdfUrl: invoice.pdfUrl || null,
    xlsUrl: invoice.transactionsReport?.xlsUrl || null,
  }
}

export const mapECurringMandate = (mandate: TDirectDebitMandates[0]): IMandateProps => {
  return {
    mandateId: mandate.id,
    status: mandate.status?.humanized || null,
    acceptedAt: mandate.acceptedAt || null,
    revokedAt: mandate.revokedAt || null,
    revocable: mandate.actions.revokable,
    variant: 'directDebit',
  }
}

export const mapTweakyMandate = (mandate: TwikeyContracts[0]): IMandateProps => {
  return {
    mandateId: mandate.id,
    status: mandate.status?.humanized || null,
    acceptedAt: mandate.invitedAt || null,
    revokedAt: mandate.cancelledAt || null,
    revocable: mandate.actions.revocable,
    variant: 'splitBilling',
    splitBillingContractId: mandate.splitBillingContractId || null,
  }
}
