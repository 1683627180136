import { ROUTE_NAMES } from '../Layouts/Authorized/interfaces'
import { routes } from '../Layouts/Authorized/routes'

const translatedRoutes: { [key: string]: string } = {}

Object.keys(ROUTE_NAMES)
  .filter((route: string) => isNaN(Number(route)))
  .forEach((route: string) => {
    translatedRoutes[route] = ''
  })

// Here we will replace the placeholders or numeric ids in urls like:
// /some/path/:placeholder/here -> /some/path/here
// /some/path/12/here -> /some/path/here
// as the placeholders or ids can be in the middle or end of the string, we need to account for both occurrences
const replacePlaceholders = (path: string): string => {
  return path
    .replace(/\/(\d+|[0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12})\//, '/:id/')
    .replace(/\/(\d+|[0-9a-f]{8}(-[0-9a-f]{4}){3}-[0-9a-f]{12})$/, '/:id')
}

const injectIdFromPathInUrl = (path: string, url: string): string => {
  const matches = path.match(/(\d+?)(?=\/|$)/)
  if (!matches) return url
  return url.replace(/\/:id/, `/${matches[0]}`)
}

const getKeyForPath = (path: string): string | null => {
  const normalizedPath = replacePlaceholders(path)
  const index = Object.keys(routes).find((key) => {
    // @ts-ignore
    const matchingRoute = routes[key]
    const normalizedRoute = replacePlaceholders(matchingRoute)

    return normalizedRoute === normalizedPath
  })
  if (!index) return null
  return ROUTE_NAMES[+index]
}

export const getPathForKey = (routeKey: string, path: string): string | null => {
  // @ts-ignore
  const stripped = routeKey.replace(/^routes:/, '')
  const index = Object.keys(ROUTE_NAMES).find((key) => {
    return ROUTE_NAMES[+key] === stripped
  })
  if (!index) return null
  // @ts-ignore
  const url = routes[index]
  return injectIdFromPathInUrl(path, url)
}

export const getTranslationKeyForRoute = (path: string): string => {
  const key = getKeyForPath(path)
  return `routes:${key}`
}
