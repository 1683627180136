import { TSection, TSelectedSections } from './interface'

export const selectedSectionsDataKey = 'selectedSectionsData'

// Example of an object
// { status: "ALL", period: "SELECTION" }

export const getSelectedSections = (): TSelectedSections => {
  return JSON.parse(window.sessionStorage.getItem(selectedSectionsDataKey) || '{}')
}

export const saveSelectedSectionsInStorage = (input: TSelectedSections): void => {
  window.sessionStorage.setItem(selectedSectionsDataKey, JSON.stringify(input))
}

export const deleteSelectedSection = (section: TSection): void => {
  const data = getSelectedSections()
  const newData = { ...data }
  delete newData[section]
  saveSelectedSectionsInStorage(newData)
}

export const deleteAllSelectedSections = (): void => {
  window.sessionStorage.removeItem(selectedSectionsDataKey)
}
