import React from 'react'

import { Icon } from '../Icon'
import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ListItemButton: React.FC<IProps> = ({ label, onClick }): JSX.Element => {
  return (
    <div role="button" onClick={onClick} tabIndex={0} className={Styles.listItemButton}>
      <Typography className={Styles.label} variant="h1" element="h3">
        {label}
      </Typography>
      <Icon name="chevron-right" width={12} height={12} />
    </div>
  )
}
