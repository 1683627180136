import React from 'react'
import { Col, Row } from 'react-simple-flex-grid'

import { Typography } from '../../../../Components'
import { useTranslationDecorator } from '../../../../Hooks/useTranslationDecorator'
import { formatDateString } from '../../../../Lib/Formatters/DateFormatter'
import { IProps } from './interfaces'
import { Revoke } from './Revoke'
import Styles from './styles.module.scss'

export const Mandate: React.FC<IProps> = ({
  mandateId,
  revokedAt,
  acceptedAt,
  status,
  revocable,
  variant,
  splitBillingContractId,
}): JSX.Element => {
  const { t, locale } = useTranslationDecorator()
  const formatString = 'd MMMM yyyy'

  const data: { [key: string]: string | null } = {
    [t('Current status')]: status,
    ...(acceptedAt && { [t('Issued on')]: formatDateString(acceptedAt, formatString, locale) }),
    ...(revokedAt && { [t('Revoked on')]: formatDateString(revokedAt, formatString, locale) }),
  }

  const id = ((): string => {
    if (variant === 'directDebit') return mandateId
    return splitBillingContractId || mandateId
  })()

  return (
    <Row data-testid={`mandate-${mandateId}`} className={Styles.row}>
      <Col xs={12} sm={3} md={3}>
        <Typography className={Styles.mandateId} variant="h1" element="p">
          {`${t('Mandate ID')} ${id}`}
        </Typography>
      </Col>

      <Col xs={12} sm={5}>
        {Object.keys(data).map((label, index) => {
          const value = data[label]

          return (
            <Row key={index} data-testid={label.replaceAll(' ', '-')} className={Styles.dataRow}>
              <Col xs={6}>
                <Typography className={Styles.label} key={`${label}-${index}`} variant="body-2">
                  {label}
                </Typography>
              </Col>
              <Col xs={6}>
                <Typography className={Styles.value} variant="h2" element="p">
                  {value}
                </Typography>
              </Col>
            </Row>
          )
        })}
      </Col>

      {revocable && (
        <Col className={Styles.buttonWrapper} xs={{ offset: 6, span: 12 }} sm={{ offset: 1, span: 3 }}>
          <Revoke mandateId={mandateId} variant={variant} />
        </Col>
      )}
    </Row>
  )
}
