import { t } from 'i18next'

import { TPage } from '../../../../../../Components/Breadcrumbs/interfaces'
import { getPathForKey, getTranslationKeyForRoute } from '../../../../../../Lib/routeHelpers'

const getPartialPathOfNodeIndex = (path: string, nodeIndex: number): string => {
  return path
    .split('/')
    .slice(0, nodeIndex + 2)
    .join('/')
}

const getTotalLevelsOfPath = (path: string): number => {
  const key = getTranslationKeyForRoute(path)
  const translation = t(key)

  return translation.split('#').length
}

export const getPagesBasedOnPath = (path: string): TPage[] => {
  const pages: TPage[] = []
  const totalLevels = getTotalLevelsOfPath(path)

  for (let i = 0; i < totalLevels; i += 1) {
    // we need the full path when it's the last level to include the ID segment of the path.
    const partialPath = totalLevels - 1 === i ? path : getPartialPathOfNodeIndex(path, i)
    const key = getTranslationKeyForRoute(partialPath)
    const url = getPathForKey(key, partialPath)
    const translation = t(key).split('#').at(-1)

    if (translation && key !== 'routes:null') {
      pages.push({
        title: translation,
        url: url || '',
      })
    }
  }
  return pages
}
