import React from 'react'

import { Typography } from '../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Title: React.FC<IProps> = ({ title }): JSX.Element => {
  return (
    <Typography className={Styles.title} variant="body-2">
      {title}
    </Typography>
  )
}
