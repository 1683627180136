import { convertCamelCaseToKebabCase } from '../../Lib/sanitizers'
import { IHSLA, IRGBA } from '../../Theme/interfaces'
import {
  convertHexToRGBA,
  darkenColor,
  getContrastColor,
  HSLAObjectToHSLAString,
  lightenColor,
} from '../../Theme/utils'
import { ICSSColorVarsMap, IVariantMap, TColorsFromTheme } from './interfaces'

// See https://github.com/microsoft/TypeScript/pull/12253
const getColorKeys = Object.keys as <TColorsFromTheme>(colorsObj: TColorsFromTheme) => (keyof TColorsFromTheme)[]

// Various opacities in which to output the CSS vars
const opacityMap = [20, 40, 60, 80]

// Variants that need to be made based on given colors
const variantMap: IVariantMap = {
  contrast: (_hsla, rgba) => convertHexToRGBA(getContrastColor(rgba), rgba.alpha),
  dark: (hsla) => darkenColor(hsla),
  default: (hsla) => HSLAObjectToHSLAString(hsla),
  light: (hsla) => lightenColor(hsla),
}

/**
 * This function generates a set of CSS vars in different variants, with their various opacities. The CSS vars
 * are always kebab-cased to be compliant with our CSS linting in other places.
 *
 * @param colorName: name of the color -- will be used as CSS Variable name
 * @param hsla: HSLA color definition
 * @param rgba: RGBA color definition
 * @returns map of CSS vars:
 *
 * ```
 * {
 *    '--primary-color': 'rgba(10, 20, 30, 1)',
 *    '--primary-color-opacity-20': 'rgba(10, 20, 30, 0.2)'
 * }
 * ```
 */
export const generateCSSColorVars = (colorName: string, hsla: IHSLA, rgba: IRGBA): ICSSColorVarsMap => {
  return Object.keys(variantMap).reduce((accumulator: ICSSColorVarsMap, variantName) => {
    const colorGeneratorFn = variantMap[variantName]
    const kebabCasedColorName = convertCamelCaseToKebabCase(colorName)

    // If the variant is called 'default', only the color name will be used as variantName.
    const cssVarName =
      variantName !== 'default' ? `--${kebabCasedColorName}-${variantName}` : `--${kebabCasedColorName}`

    // Create default variant first
    accumulator[cssVarName] = colorGeneratorFn(hsla, rgba)

    opacityMap.forEach((opacity) => {
      // Now create and add various opacities
      accumulator[`${cssVarName}-opacity-${opacity}`] = colorGeneratorFn(
        { ...hsla, alpha: opacity / 100 },
        { ...rgba, alpha: opacity / 100 }
      )
    })

    return accumulator
  }, {})
}

/**
 * This function generates a set of CSS vars in different variants, with their various opacities for multiple colors.
 * It injects these CSS vars directly in the document style, making them available in CSS. Example usage:
 *
 * ```
 * {
 *    background-color: var(--secondary-color);
 *    color: var(--primary-color-opacity-20);
 * }
 * ```
 *
 * @param colors: Map of color names with a HSLA and RGBA configuration per color
 */
export const setCSSColorVarsForColors = (colors: TColorsFromTheme): void =>
  getColorKeys(colors).forEach((colorKey) => {
    const hslaValues = colors[colorKey]?.hsla
    const rgbaValues = colors[colorKey]?.rgba

    if (!hslaValues || !rgbaValues) return

    const cssVarsMap = generateCSSColorVars(colorKey, hslaValues, rgbaValues)
    const primaryColor = cssVarsMap['--primary-color']
    if (primaryColor) window.localStorage.setItem('primaryColor', primaryColor)

    // Add each css var to the document style
    Object.keys(cssVarsMap).map((cssVarName) =>
      document.documentElement.style.setProperty(cssVarName, cssVarsMap[cssVarName])
    )
  })
