import React from 'react'
import { components, SingleValue } from 'react-select'
import { MenuListProps } from 'react-select/dist/declarations/src/components/Menu'

import { IOption } from '../../interfaces'
import Styles from './styles.module.scss'

export const MenuList: React.FC<MenuListProps<SingleValue<IOption>, false>> = ({
  children,
  className,
  ...innerProps
}): JSX.Element => {
  const classNames = [Styles.menuList, ...(className ? [className] : [])].join(' ')
  return (
    <components.MenuList className={classNames} {...innerProps}>
      {children}
    </components.MenuList>
  )
}
