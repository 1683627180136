import React from 'react'

import { ErrorsBlock } from '../../../ErrorsBlock'
import { MODAL_ERROR_PORTAL } from './helpers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ErrorsSection: React.FC<IProps> = ({ errors = [] }): JSX.Element => {
  return (
    <React.Fragment>
      {/* Errors passed as prop */}
      {errors.length > 0 && (
        <div className={Styles.errorsBlockWrapper}>
          <ErrorsBlock errors={errors} />
        </div>
      )}

      {/*
          Some modals contain complex logic and can't pass for example errors directly as prop.
          This portal makes it easier to inject elements into the header of the modal.
          Use this portal to add elements below the title/description
         */}
      <div id={MODAL_ERROR_PORTAL} className={Styles.portal} />
    </React.Fragment>
  )
}
