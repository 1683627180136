import React, { useEffect, useState } from 'react'

import { Icon } from '../../Icon'
import { Input } from '../Input'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Checkbox: React.FC<IProps> = ({
  'aria-label': ariaLabel,
  checked,
  errors,
  id,
  label,
  onChange,
  required,
  disabled,
  className,
  ...otherProps
}): JSX.Element => {
  const [isChecked, setIsChecked] = useState<boolean>(checked)

  // Somehow without this the state is not being set properly when you toggle it in storybook?
  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const handleOnChange = (): void => {
    if (disabled) return

    const newValue = !isChecked
    setIsChecked(newValue)
    onChange(newValue)
  }

  const classNames: string[] = [Styles.checkbox, ...(className ? [className] : [])]

  return (
    <div
      data-testid={`wrapper-${id}`}
      className={[Styles.checkboxWrapper, ...(disabled ? [Styles.disabled] : [])].join(' ')}
      onClick={(event): void => {
        event.stopPropagation()
        handleOnChange()
      }}
      tabIndex={0}
      role="link" // here we provide the role='link' so the screenreaders can reach the nested label link.
      // @TODO: role='checkbox' aria-checked={isChecked} is the correct typing for this div. However, as the component is setup screenreaders can't reach nested links in the label. Refactor the component so the setup is as it should be and not hacked.
      aria-label={ariaLabel}
    >
      <div
        id={`checkbox-${id}`}
        className={`${classNames.join(' ')} ${isChecked ? Styles.checked : ''} ${disabled ? Styles.disabled : ''}`}
      >
        <Icon name="check" />
      </div>

      <Input
        {...otherProps}
        aria-label={ariaLabel || label}
        labelCssClasses={[Styles.inputLabel]}
        wrapperClassName={Styles.inputWrapper}
        errors={errors}
        id={id}
        label={label}
        required={required}
        type="hidden"
        value={isChecked.toString()}
        disabled={disabled}
        tabIndex={0}
        role="link"
      />
    </div>
  )
}
