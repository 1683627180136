import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { CurrentUserContext } from '../../Providers'
import { Icon } from '../Icon'
import Styles from './styles.module.scss'

export const BecameEmployeeWarning = (): JSX.Element | null => {
  const { t } = useTranslation()
  const { becameEmployee, fullName } = useContext(CurrentUserContext)
  if (!becameEmployee) return null

  return (
    <div data-testid="became-employee-warning" className={Styles.becameEmployeeWarning}>
      <Icon name="warning" />
      {t(`Attention! You are currently logged in as {{ fullName }}`, { fullName: fullName() })}
      <Icon name="warning" />
    </div>
  )
}
