import React from 'react'

import { useDisableBodyScroll } from '../../Hooks/useDisableBodyScroll'
import { ModalContainer } from '../ModalContainer'
import { Footer } from './Components'
import { Header } from './Components/Header'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Modal: React.FC<IProps> = ({
  id,
  className,
  title,
  subTitle,
  children,
  footer,
  onClose,
  errors = [],
  hideHeader = false,
}): JSX.Element => {
  useDisableBodyScroll()

  return (
    <ModalContainer
      id={id}
      aria-label={title}
      title={title || 'Modal'}
      className={[Styles.modal, ...(className ? [className] : [])].join(' ')}
      onClose={onClose}
      withPadding={false}
      show
    >
      {!hideHeader && <Header title={title || 'Modal'} subTitle={subTitle} errors={errors} />}

      {children && (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        <div className={Styles.content} tabIndex={0}>
          {children}
        </div>
      )}
      {footer && <Footer>{footer}</Footer>}
    </ModalContainer>
  )
}
