import React from 'react'
import { useTranslation } from 'react-i18next'

import Styles from './styles.module.scss'

export const NoOptionsMessage = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div className={Styles.container}>
      <span>{t('No options')}</span>
    </div>
  )
}
