import React from 'react'

import Styles from './styles.module.scss'

export const MainLoading = (): JSX.Element => {
  const iconColor = localStorage.getItem('primaryColor') || 'var(--primary-color)'

  return (
    <div className={Styles.loadingContainer} data-testid="main-loading" aria-label="Loading" role="img">
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor={iconColor} />
            <stop offset="100%" stopColor="#fff" />
          </linearGradient>
        </defs>
        <path
          d="M60 51.5L30.5 51.5C21 51.5 8.5 45 8.5 30.5C8.5 18.5 17 8.50002 30.5 8.50002C38.5 8.50002 51.5 14.5 51.5 30.5V43"
          stroke="url(#linear)" // Reisbalans green
          strokeWidth="17"
          className="svg-reisbalans-loading"
          strokeDasharray="146.65155029296875px"
          strokeDashoffset="146.65155029296875px"
        >
          <animate attributeName="stroke-dashoffset" dur="9s" to="-1000" repeatCount="indefinite" />
        </path>
      </svg>
    </div>
  )
}
