import React from 'react'

import { useGetTaskListQuery } from '../../Lib/graphql'
import { TaskSidebarComponent } from './components'

export const TaskSidebar: React.FC = (): JSX.Element => {
  const { data, error, loading } = useGetTaskListQuery()

  return (
    <TaskSidebarComponent
      error={error}
      loading={loading}
      tasks={data?.me?.tasks}
      confirmableMobilityEventsCount={data?.me?.mobilityEventsAggregate?.count}
    />
  )
}
