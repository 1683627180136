import React, { useRef, useState } from 'react'

import { useOnClickOutside } from '../../Hooks/useClickedOutside/useOnClickOutside'
import { ProfileToggle, SettingsListComponent } from './components'
import Styles from './styles.module.scss'

export const ProfileSidebar: React.FC = (): JSX.Element | null => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

  const toggleSidebarOpen = (): void => {
    setSidebarOpen((previousState) => !previousState)
  }

  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside({ handler: (): void => setSidebarOpen(false), ref })

  return (
    <React.Fragment>
      <div ref={ref}>
        <div>
          <ProfileToggle collapsed={!sidebarOpen} onClick={toggleSidebarOpen} />
        </div>
      </div>
      <div className={`${Styles.settingsListSidebar} ${sidebarOpen ? Styles.open : ''}`}>
        <SettingsListComponent />
      </div>
    </React.Fragment>
  )
}
