import { iconNames, TIconName } from './iconNames'

export type TIconAliases =
  | 'electricity'
  | 'electric_car'
  | 'ov_chip'
  | 'legs'
  | 'reisbalans'
  | 'behavior'
  | 'location_arrow'
  | 'other_ov'
  | 'public_transport'
  | 'surcharge'
  | 'unknown'
  | 'tram'
  | 'ferry'
  | 'transfer'
  | 'bike'
  | 'shuttel_taxi'
  | 'shuttel_tranzer_etickets'
  | 'shuttel_vecore'
  | 'lease_bike'
  | 'yellowbrick_sp'
  | 'light_rail_station'
  | 'subway_station'
  | 'donkey_republic'
  | 'shuttel_check'
  | 'shuttel_my_wheels'
  | 'shuttel_sixt'
  | 'tranzer_etickets'
  | 'amber'
  | 'eneco_dongle'
  | 'shared_vehicle'
  | 'all_modalities'

const publicTransport = 'public-transport'

export const iconAliases: { [alias in TIconAliases]: TIconName } = {
  electricity: 'electric',
  electric_car: 'electric',
  ov_chip: publicTransport,
  legs: 'walking',
  reisbalans: 'star',
  behavior: 'km-gauge',
  location_arrow: 'location-cross',
  other_ov: 'public-transport-card',
  public_transport: publicTransport,
  surcharge: 'other-expenses',
  unknown: 'question',
  tram: 'train',
  ferry: 'boat',
  transfer: 'walking',
  bike: 'bicycle',
  shuttel_taxi: publicTransport,
  shuttel_tranzer_etickets: publicTransport,
  shuttel_vecore: 'car',
  lease_bike: 'bicycle',
  yellowbrick_sp: 'card',
  light_rail_station: 'light-rail-station',
  subway_station: 'light-rail-station',
  donkey_republic: 'bicycle',
  shuttel_check: 'scooter',
  shuttel_my_wheels: 'car',
  shuttel_sixt: 'car',
  tranzer_etickets: 'e-ticket',
  amber: 'car',
  eneco_dongle: 'electricity',
  shared_vehicle: 'rental_car',
  all_modalities: 'unknown',
}

const isIconAlias = (name: string): name is TIconAliases => {
  return Object.keys(iconAliases).includes(name)
}

export const getIconName = (name: TIconName | TIconAliases): string => {
  if (isIconAlias(name)) return iconAliases[name]
  return iconNames.includes(name) ? name : 'unknown'
}
