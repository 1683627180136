import React from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../Components/Typography'
import Styles from '../Intro/styles.module.scss'

export const Cancelled = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <section data-testid="section-cancelled">
      <Typography className={Styles.title} variant="body-2">
        {t('Automatic authorisation cancelled...')}
      </Typography>

      <Typography variant="body-2" className={Styles.description}>
        {t('You have terminated the process or something has gone wrong.')}
      </Typography>
    </section>
  )
}
