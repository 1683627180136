import { useApolloClient } from '@apollo/client'
import { debounce } from 'debounce'
import { useState } from 'react'

import { GetPlaceSuggestionsDocument } from '../../../Lib/graphql'
import { processSuggestionResult } from './helpers'
import { IOption, IUseLocationHook } from './interfaces'

export const useLocationSelector = (): IUseLocationHook => {
  const [loading, setLoading] = useState<boolean>(false)
  const debounceTimeInMs = 250
  const apolloClient = useApolloClient()

  const getAsyncOptions = async (inputValue: string): Promise<IOption[]> => {
    const result = await apolloClient.query({
      query: GetPlaceSuggestionsDocument,
      variables: { startsWith: inputValue },
    })

    return processSuggestionResult(result)
  }

  const loadSuggestions = debounce((inputText: string, callback: (options: IOption[]) => void): void => {
    setLoading(true)

    getAsyncOptions(inputText)
      // eslint-disable-next-line promise/no-callback-in-promise
      .then((options) => callback(options))
      .catch(console.error)
    setLoading(false)
  }, debounceTimeInMs)

  return { loadSuggestions, loading }
}
