import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SingleValue } from 'react-select'

import LocationGif from '../../../../Assets/Gif/GPS-animation.gif'
import { TGcsCoordinates } from '../../../../Hooks/useMap/interfaces'
import { MediaQueriesContext } from '../../../../Providers'
import { AnimatedSection } from '../../../AnimatedSection'
import { LocationSelect } from '../../../FormComponents/LocationSelect'
import { IOption } from '../../../FormComponents/LocationSelect/interfaces'
import { Map } from '../../../Map'
import { PinMarker } from '../../../Map/Components/PinMarker'
import { getDefaultPlaceholder, getDescription, getTitle } from './helpers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const LocationFix: React.FC<IProps> = ({ type, onChange }): JSX.Element => {
  const { t } = useTranslation()
  const { isLGScreen } = useContext(MediaQueriesContext)
  const defaultPlaceholder = getDefaultPlaceholder(type, t)
  const [markerPosition, setMarkerPosition] = useState<TGcsCoordinates>()
  const [zoomLevel, setZoomLevel] = useState<number | null>(7)
  const [selectedLocation, setSelectedLocation] = useState<SingleValue<IOption>>()
  const [placeholder, setPlaceholder] = useState<string>(defaultPlaceholder)
  const mapRef = useRef<HTMLDivElement>(null)

  const clearSearch = (): void => {
    setSelectedLocation(null)
  }

  const getCoordinatesAsString = (coordinates: TGcsCoordinates): string => {
    return `${coordinates.latitude.toFixed(5)}, ${coordinates.longitude.toFixed(5)}`
  }

  const isSamePlace = (coordinatesA: TGcsCoordinates, coordinatesB: TGcsCoordinates): boolean => {
    return (
      coordinatesA.latitude.toFixed(5) === coordinatesB.latitude.toFixed(5) &&
      coordinatesA.longitude.toFixed(5) === coordinatesB.longitude.toFixed(5)
    )
  }

  const handleMarkerPositionChange = (_markerPosition: TGcsCoordinates): void => {
    setMarkerPosition((oldValue) => {
      if (oldValue && isSamePlace(oldValue, _markerPosition)) return oldValue

      return _markerPosition
    })
  }

  const handleOnSearchChange = (location: SingleValue<IOption>): void => {
    if (!location || !location.value.coordinates) return

    setSelectedLocation(location)
    handleMarkerPositionChange(location.value.coordinates)
    setZoomLevel(12)
  }

  const updateInputPlaceholder = useCallback((): void => {
    clearSearch()

    if (markerPosition) setPlaceholder(getCoordinatesAsString(markerPosition))
    else setPlaceholder(defaultPlaceholder)
  }, [defaultPlaceholder, markerPosition])

  const handleOnMoveEnd = (newCenterCoordinates: TGcsCoordinates): void => {
    handleMarkerPositionChange(newCenterCoordinates)
  }

  const onSearchMenuOpen = (): void => {
    setPlaceholder(defaultPlaceholder)
  }

  const onSearchMenuClose = (): void => {
    updateInputPlaceholder()
  }

  const onMapZoom = (): void => {
    setZoomLevel(null)
  }

  useEffect(() => {
    if (markerPosition) {
      onChange(markerPosition)
      updateInputPlaceholder()
    }
  }, [markerPosition, onChange, updateInputPlaceholder])

  return (
    <div className={Styles.sectionWrapper}>
      <div className={Styles.innerWrapper}>
        {isLGScreen && (
          <AnimatedSection title={getTitle(type, t)} description={getDescription(type, t)} image={LocationGif} />
        )}

        <div className={Styles.searchBar}>
          <LocationSelect
            id="search-location"
            onChange={handleOnSearchChange}
            placeholder={placeholder}
            selected={selectedLocation}
            isClearable={false}
            onMenuOpen={onSearchMenuOpen}
            onMenuClose={onSearchMenuClose}
          />
        </div>
      </div>

      <div className={Styles.mapWrapper} ref={mapRef}>
        <Map
          zoomLevel={zoomLevel}
          center={markerPosition}
          disableScrollZoom
          showMapFullscreenControl={false}
          onMoveEnd={handleOnMoveEnd}
          onMapZoom={onMapZoom}
        />
        <PinMarker mapRef={mapRef} />
      </div>
    </div>
  )
}
