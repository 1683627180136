import React from 'react'
import { components, SingleValue, SingleValueProps } from 'react-select'

import { IOption } from '../../interfaces'

export const SingleValueComponent: React.FC<SingleValueProps<SingleValue<IOption>, false>> = (props) => {
  const { selectProps } = props

  if (selectProps.menuIsOpen) return <div />

  return <components.SingleValue {...props} />
}
