import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const CurvedSection: React.FC<IProps> = ({
  variant = 'white',
  padding = 8,
  withShadow,
  children,
  className,
}): JSX.Element => {
  return (
    <div
      className={`${Styles.curvedSection} ${Styles[variant]} ${withShadow ? Styles.shadow : ''} ${className || ''}`}
      style={{ padding }}
    >
      {children}
    </div>
  )
}
