import { ApolloQueryResult } from '@apollo/client'

import { IGetPlaceSuggestionsQuery } from '../../../Lib/graphql'
import { IOption, TFavorite, TPlacesResult } from './interfaces'

export const processSuggestionResult = (result: ApolloQueryResult<IGetPlaceSuggestionsQuery>): IOption[] => {
  const options: IOption[] = []
  const places = result.data?.places?.suggest || []
  const favorites = result.data?.me?.favorites || []

  favorites.forEach((favorite: TFavorite): void => {
    options.push({
      label: favorite.label,
      value: {
        placeType: 'favorite',
        ...favorite,
      },
    })
  })

  places.forEach((place: TPlacesResult): void => {
    options.push({
      label: place.text,
      value: {
        id: place.placeId,
        locationId: undefined,
        location: place.text,
        placeType: 'place',
      },
    })
  })

  return options
}
