import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../../../Components/Typography'
import Styles from '../Intro/styles.module.scss'

export const Active = (): JSX.Element => {
  const { t } = useTranslation()
  useEffect(() => {
    // Because this component is shared on a static rendered page, we have no
    // other option then to reload the page to show the update
    setTimeout(() => {
      window.location.reload()
    }, 5000)
  })

  return (
    <section data-testid="section-active">
      <Typography className={Styles.title} variant="body-2">
        {t('Automatic authorisation issued')}
      </Typography>

      <Typography className={Styles.description} variant="body-2">
        {t('Your automatic authorisation has been received, the page is reloading...')}
      </Typography>
    </section>
  )
}
