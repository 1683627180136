import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ListWrapper: React.FC<IProps> = ({ id, children }): JSX.Element => {
  return (
    <div id={id} className={Styles.listWrapper}>
      {children}
    </div>
  )
}
