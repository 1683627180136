import { endOfWeek, parse, startOfWeek } from 'date-fns'
import { enGB, nl } from 'date-fns/locale'

import { TLocale } from '../../sharedInterfaces'
import { TICULocale } from './interfaces'

export const capitalizeString = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const localeForDateFn = (locale: TLocale): Locale => {
  return locale === 'nl' ? nl : enGB
}

export const localeForICU = (locale: TLocale): TICULocale => {
  return locale === 'nl' ? 'nl-nl' : 'en-gb'
}

// Parse a dateString to a Date object. The given format is the default format used by our beloved GraphQL backend
export const parseDateString = (dateString: string, format = 'yyyy-MM-dd HH:mm:ss X'): Date | null => {
  try {
    return parse(dateString, format, new Date())
  } catch (e: any) {
    console.error(e.message)
    return null
  }
}

// Check whether a date is valid (i.e. it is not "Invalid Date")
export const isValidDate = (dateObj: Date | null | undefined): boolean =>
  dateObj instanceof Date && !isNaN(dateObj.getTime())

// monday
export const firstDayOfWeek = (date: Date): Date => {
  return startOfWeek(date, { weekStartsOn: 1 })
}

// sunday
export const lastDayOfWeek = (date: Date): Date => {
  return endOfWeek(date, { weekStartsOn: 1 })
}
