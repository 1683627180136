import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../Components'
import { Typography } from '../../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ExternalAgreement: React.FC<IProps> = ({ url }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <div data-testid="eCurring-agreement" className={Styles.externalAgreement}>
      <Typography className={Styles.title} variant="body-2">
        {t('Direct debit authorisation')}
      </Typography>

      <Button
        id="create-external-button"
        className={Styles.buttonLink}
        variant="primary"
        data-testid="create-external-button"
        onClick={(): void => {
          window.open(url)
        }}
      >
        {t('Go to eCurring')}
      </Button>

      <Typography className={Styles.subInfo} variant="body-2">
        {t(
          'You will be redirected to the secure area of the eCurring website where we will ask you to make a payment of €0.01 via iDEAL, authorising the automatic collection of the usage fee not reimbursed by your employer. After completion, you will be redirected to {{ platform }} to order your mobility card.'
        )}
      </Typography>
    </div>
  )
}
