import { format } from 'date-fns'
import { TFunction } from 'i18next'

import { parseDate } from '../../../Lib/Formatters/DateFormatter'
import { SettlementVariantEnum, TAllSettlement, TMainOption, TSubOption } from './interfaces'

export const queryParamId = 'id'

export const mapToSubOption = (settlement: TAllSettlement, translate: TFunction): TSubOption => {
  const { happenedAt } = settlement
  return {
    label: translate('on: {{ date }}', {
      date: happenedAt ? format(parseDate(happenedAt), 'dd-MM-yyyy') : translate('Unknown'),
    }),
    value: settlement,
  }
}

export const getMainOptions = (translate: TFunction): TMainOption[] => [
  { label: translate('Total gross result'), value: SettlementVariantEnum.GROSS },
  { label: translate('Total net result', { context: 'dropdown' }), value: SettlementVariantEnum.NET },
  {
    label: translate('Settlement amount'),
    value: SettlementVariantEnum.ACCOUNTED_SETTLEMENT,
  },
]
