import { StylesConfig } from 'react-select'

// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import borders from '../../../Theme/borders.module.scss'
// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import colors from '../../../Theme/colors.module.scss'

export const selectStyle: StylesConfig = {
  container: (provided) => {
    return {
      ...provided,
      width: '100%',
    }
  },
  control: (provided, state) => {
    const borderColor = state.isFocused ? colors.gray600 : colors.gray200
    const outline = state.isFocused ? `1px solid ${colors.gray450}` : 'none'

    return {
      ...provided,
      color: colors.gray900,
      borderStyle: borders.style,
      borderColor,
      boxShadow: 'none',
      outline,
      '&:hover': {
        borderColor,
        boxShadow: 'none',
        outline,
      },
      '&:focus': {
        borderColor,
        boxShadow: 'none',
        outline,
      },
      minHeight: 40,
      zIndex: 1,
    }
  },
  clearIndicator: (provided) => ({
    ...provided,
    color: colors.gray450,
    marginRight: '-10px',
    cursor: 'pointer',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
  input: (provided) => ({
    ...provided,
    'input[type=text]': {
      height: 'auto',
      margin: 0,
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.gray200,
    fontWeight: '400',
    fontSize: '14px',
  }),
  option: (provided) => {
    return {
      ...provided,
      color: colors.gray900,
      fontWeight: 'normal',
      fontSize: '14px',
      backgroundColor: colors.white,
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: colors.gray175,
        color: colors.gray900,
      },
    }
  },
  singleValue: (provided) => ({
    ...provided,
    color: colors.gray900,
    fontWeight: '400',
    fontSize: '14px',
  }),
  menu: (provided) => {
    return {
      ...provided,
      zIndex: 2,
    }
  },
}
