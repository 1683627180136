export enum ROUTE_NAMES {
  ERROR,
  LOGIN,
  MFA_CONFIGURE,
  MFA_LOGIN,
  HELP,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  WELCOME,
  REAUTHORIZE,
  SESSION_EXPIRED,
}

export type TRoutes = {
  [route in ROUTE_NAMES]: string
}
