import { RefObject, useEffect, useRef, useState } from 'react'

import { Dimension } from './interfaces'

export const useResponsiveDimensionOfRef = (elementRef: RefObject<HTMLElement>): Dimension => {
  const [dimension, setDimension] = useState<Dimension>({ width: 0, height: 0 })
  const observer = useRef<ResizeObserver>(
    new ResizeObserver((entries) => {
      const { inlineSize, blockSize } = entries[0].borderBoxSize[0]

      setDimension({ width: inlineSize, height: blockSize })
    })
  )
  const currentObserver = observer.current
  const currentElement = elementRef.current

  useEffect(() => {
    if (currentElement) currentObserver.observe(currentElement)

    return () => {
      if (currentElement) currentObserver.unobserve(currentElement)
    }
  }, [observer, currentObserver, currentElement])

  return dimension
}
