import { TLocale } from '../sharedInterfaces'

export type TCurrency = 'EUR' | 'USD' | 'GBP' | undefined

export interface IOptions {
  precision?: number
  currency?: TCurrency
  asHTML?: boolean
  hideCurrencySymbol?: boolean
}

const formatAsHtml = (formatter: Intl.NumberFormat, valueInCents: number, options: IOptions): string => {
  const parts = formatter.formatToParts(valueInCents)
  const between = parts
    .filter((entry) => entry.type !== 'fraction' && entry.type !== 'decimal' && entry.type !== 'currency')
    .map((entry) => entry.value)
    .join('')
    .trim()

  const currency = parts.find((part) => part.type === 'currency')?.value
  const fraction = parts.find((part) => part.type === 'fraction')?.value
  const decimal = parts.find((part) => part.type === 'decimal')?.value
  let result = ''
  if (!options.hideCurrencySymbol) result += `<span data-currency-symbol>${currency} </span>`
  result += `<span data-currency-value>${between}</span>`

  if (fraction)
    result += `<span data-currency-tail><span data-currency-decimal>${decimal}</span><span data-currency-fraction>${fraction}</span></span>`

  return `<span data-currency ${
    valueInCents < 0 ? 'data-currency-negative' : 'data-currency-positive'
  }>${result}</span>`
}

const customFormatter = (locale: TLocale, valueInCents: number, options: IOptions): string => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: options.currency || 'EUR',
    minimumFractionDigits: options.precision === undefined ? 2 : options.precision,
    maximumFractionDigits: options.precision === undefined ? 2 : options.precision,
  })
  return options.asHTML ? formatAsHtml(formatter, valueInCents, options) : formatter.format(valueInCents)
}

// Using the parseInt to prevent XSS injections as we use dangerouslySetInnerHTML in the component.
export const formatCurrency = (valueInCents: number, locale: TLocale, options?: IOptions): string => {
  // eslint-disable-next-line no-param-reassign
  if (!options) options = { currency: 'EUR', precision: 2, asHTML: false }
  return customFormatter(locale, parseInt(`${valueInCents}`, 10) / 100, options)
}
