import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { useRevertBetaInterfaceOptInMutation } from '../../Lib/graphql'
import { CurrentUserContext } from '../../Providers'
import { Icon } from '../Icon'
import { Typography } from '../Typography'
import Styles from './styles.module.scss'

export const RevertInterfaceBeta = (): JSX.Element | null => {
  const { t } = useTranslation()
  const [mutation] = useRevertBetaInterfaceOptInMutation()
  const { optedInForBetaInterface } = useContext(CurrentUserContext)

  if (!optedInForBetaInterface) return null

  const revert = (): void => {
    mutation()
      .then(() => {
        window.location.href = import.meta.env.REACT_APP_LEGACY_APP_URL || '#'
      })
      .catch((err) => console.error('Could not revert to the old interface because: ', err.message))
  }

  return (
    <div
      className={Styles.revertInterfaceBeta}
      onClick={revert}
      data-testid="RevertInterfaceBeta"
      role="button"
      tabIndex={0}
      aria-label={t('Revert interface')}
    >
      <Icon name="undo" />
      <Typography variant="body-1">{t('Revert interface')}</Typography>
    </div>
  )
}
