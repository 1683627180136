import { t } from 'i18next'

import { version } from '../../../package.json'

export const LAST_OPENED_APP_VERSION_KEY = 'lastOpenedAppVersionKey'
export const currentVersion = version

export const fetchReleaseNotes = (locale: string): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    import(`../../ReleaseNotes/${locale}/${currentVersion}.md`)
      .then((result) => {
        resolve(t(result.default))
      })
      .catch(reject)
  })
}
