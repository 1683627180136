import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { ListItemButton } from '../../../../Components/ListItemButton'
import { ListWrapper } from '../../../../Components/ListWrapper'
import { ROUTE_NAMES } from '../../../../Layouts/Authorized/interfaces'
import { routes } from '../../../../Layouts/Authorized/routes'
import { Mandate } from '../../Components/Mandate'
import { Title } from '../../Components/Title'
import { mapTweakyMandate } from '../../helpers'
import { RequestSplitBilling } from '../RequestSplitBilling'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Mandates: React.FC<IProps> = ({ mandates, canRequestContract }): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const mappedMandates = mandates.map(mapTweakyMandate)
  const limit = 3

  return (
    <div data-testid="mandates-container" className={Styles.container}>
      <Title title={t('eMandates')} />

      <ListWrapper>
        {canRequestContract && <RequestSplitBilling />}

        <ListWrapper id="mandates">
          {mappedMandates.slice(0, limit).map((mandate) => (
            <Mandate key={`mandate-${mandate.mandateId}`} {...mandate} />
          ))}
          {mappedMandates.length > limit && (
            <ListItemButton
              label={t('See all mandates')}
              onClick={(): void => navigate(routes[ROUTE_NAMES.SPLIT_BILLING_PAYMENTS_MANDATES])}
            />
          )}
        </ListWrapper>
      </ListWrapper>
    </div>
  )
}
