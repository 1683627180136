import React from 'react'

import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Footer: React.FC<IProps> = ({ children, noBorder, className }): JSX.Element => {
  return (
    <div
      className={[`${Styles.footer} ${noBorder ? Styles.noBorder : ''}`, ...(className ? [className] : [])].join(' ')}
    >
      {children}
    </div>
  )
}
