/* eslint-disable reisbalans-rules/enforce-scss-modules */
import React from 'react'
import { useTranslation } from 'react-i18next'

// @ts-ignore
import { defaulticonsize } from '../../Theme/sizes.module.scss'
import { getIconName } from './aliases'
import { IProps } from './interface'

export const Icon: React.FC<IProps> = ({
  name,
  color,
  height = defaulticonsize,
  width = defaulticonsize,
  ...svgProps
}): JSX.Element | null => {
  const { t } = useTranslation()

  const finalName = getIconName(name)

  return (
    <svg {...svgProps} width={width} height={height} aria-hidden="true" fill={color} aria-label={t(`${name} icon`)}>
      <use xlinkHref={`#icon-${finalName}`} fill={color} color={color} />
    </svg>
  )
}
