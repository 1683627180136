import { FieldMergeFunction, Observable, StoreObject } from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'

// The following complex merge strategy is a lot simpler than it looks:
// It just compares the old reference array with the new, merges those
// it already had and appends those that are new.
// See also:
// https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays-of-non-normalized-objects
export const arrayMerge: FieldMergeFunction = (
  existing: StoreObject[],
  incoming: StoreObject[],
  { mergeObjects, readField }
): StoreObject[] => {
  const merged = existing ? existing.slice(0) : []
  const allObjectIds: Record<string, number> = Object.create(null)

  if (existing) {
    existing.forEach((existingObject, index: number) => {
      const objectId = readField<string>('id', existingObject)
      if (!objectId) return
      allObjectIds[objectId] = index
    })
  }

  incoming.forEach((incomingObject) => {
    const objectId = readField<string>('id', incomingObject)
    if (!objectId) return

    const index = allObjectIds[objectId]
    if (typeof index === 'number') {
      // Merge the new object data with the existing object data.
      merged[index] = mergeObjects(merged[index], incomingObject)
    } else {
      // First time we've seen this object in this array.
      allObjectIds[objectId] = merged.length
      merged.push(incomingObject)
    }
  })

  return merged
}

export const mergeMobilityEvents: FieldMergeFunction = (
  existing: StoreObject[],
  incoming: StoreObject[],
  fieldFunctions
): StoreObject[] => {
  const { variables, readField } = fieldFunctions
  const offsetIsZero = variables?.offset === 0

  // Reset the cache if offset is equal to 0
  const existingInCache = offsetIsZero ? undefined : existing

  const result: StoreObject[] = arrayMerge(existingInCache, incoming, fieldFunctions)

  // MobilityEvents are sorted in descending order based on their happenedAt dates, with the newest events appearing first.
  const sortedObjects = [...result].sort((prev, next) => {
    const fieldName = 'happenedAt'
    const prevHappenedAt = readField<string>(fieldName, prev)
    const nextHappenedAt = readField<string>(fieldName, next)

    if (!prevHappenedAt || !nextHappenedAt) return 0
    return new Date(nextHappenedAt).getTime() - new Date(prevHappenedAt).getTime()
  })

  // When using the 'cache-and-network' fetchPolicy and having previously cached 60 mobilityEvents,
  // the default behavior is to append the cached list to the newly fetched list of 20 mobilityEvents.
  // However, this check ensures that only the desired number of items based on your offset is selected.
  // It prevents appending the cached items beyond the specified limit.
  if (variables && variables.offset !== undefined && variables.limit !== undefined && sortedObjects.length > 0) {
    const totalLength = variables.offset + variables.limit
    return sortedObjects.splice(0, totalLength)
  }

  return sortedObjects
}

export const promiseToObservable = (promise: Promise<unknown>): Observable<unknown> =>
  new Observable((subscriber) => {
    promise
      .then(
        (value) => {
          if (subscriber.closed) return

          subscriber.next(value)
          subscriber.complete()
        },
        (err) => subscriber.error(err)
      )
      .catch(() => {})
  })

export const isUnauthorizedError = (error?: NetworkError): boolean => {
  return !!error && 'statusCode' in error && error.statusCode === 401
}
