import React from 'react'

import { Typography } from '../Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ContentHeader: React.FC<IProps> = ({
  title,
  subTitle,
  className,
  postTitleContent,
  postSubTitleContent,
}): JSX.Element => {
  const css = [Styles.contentHeader, className]
  const compactedCss = css.filter((entry) => entry !== '').join(' ')

  return (
    <div id="content-header" className={compactedCss}>
      <div className={Styles.innerWrapper}>
        <div className={Styles.titleContainer}>
          <Typography variant="h1-large" className={Styles.title} element="h1">
            {title}
          </Typography>
          {postTitleContent}
        </div>

        {subTitle && (
          <Typography className={Styles.subTitle} variant="body-2">
            {subTitle}
          </Typography>
        )}

        {postSubTitleContent}
      </div>
    </div>
  )
}
