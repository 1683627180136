import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Link } from '../../../../../Components'
import { Typography } from '../../../../../Components/Typography'
import Styles from '../Intro/styles.module.scss'
import { IProps } from './interfaces'

export const Processing: React.FC<IProps> = ({ accessUrl }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <section data-testid="section-processing">
      <Typography className={Styles.title} variant="body-2">
        {t('Automatic authorisation pending...')}
      </Typography>

      <p className={Styles.description}>
        <Trans
          i18nKey="Thank you for issuing the automatic authorisation, your request is currently being processed. This may take a few minutes. You will see the current status on this screen. Did you accidentally close the screen? <portalLink>Click here</portalLink> to restart the process."
          components={{
            portalLink: (
              <Link href={accessUrl || '#'} ariaLabel={t('Link to re-open the screen and restart the process.')} />
            ),
          }}
        />
      </p>
    </section>
  )
}
