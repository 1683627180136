import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../../Components'
import { Typography } from '../../../../../Components/Typography'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Intro: React.FC<IProps> = ({ onClick }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <section data-testid="section-intro">
      <Typography className={Styles.title} variant="body-2">
        {t('You currently do not have an active direct debit.')}
      </Typography>

      <Button id="request-direct-debit" className={Styles.button} onClick={onClick}>
        {t('Request direct debit')}
      </Button>

      <p
        className={Styles.description}
        dangerouslySetInnerHTML={{
          __html: t(
            "You are allowed to use {{ platform }}' services for private purposes, please use travel purpose 'Private' when registering a transaction.<br/><br/>In order for the invoicing to be processed through your own bank account, you must issue a direct debit authorisation. Twikey, our payment partner, carries out the billing process, the direct debit is registered with them, and they will invoice you on a monthly basis. <b>Please note!</b> Keep your identifier or bank details ready. <br/><br/>If you choose not to issue a direct debit authorisation, or if you cancel your direct debit, private transaction charges will be deducted from your salary. Financial consequences of these transactions are therefore transparent to your employer."
          ),
        }}
      />
    </section>
  )
}
