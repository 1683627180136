import { TFunction } from 'i18next'

import { IFavoriteTypeEnum } from '../../../../Lib/graphql'
import { TLocationType } from '../../interfaces'

const getTranslatedType = (type: TLocationType, translate: TFunction): string => {
  const map: { [key: string]: string } = {
    [IFavoriteTypeEnum.HOME]: translate('Home address'),
    [IFavoriteTypeEnum.WORK]: translate('Work address'),
  }

  return (type && map[type] ? map[type] : translate('Address')).toLowerCase()
}

export const getTitle = (type: TLocationType, translate: TFunction): string => {
  return translate('Determine the location of your {{ type }}', { type: getTranslatedType(type, translate) })
}

export const getDescription = (type: TLocationType, translate: TFunction): string => {
  return translate(
    'Use the search field to find your {{ type }}. Then position the map to ensure the pin indicates the correct location of your {{ type }}.',
    { type: getTranslatedType(type, translate) }
  )
}

export const getDefaultPlaceholder = (type: TLocationType, translate: TFunction): string => {
  return translate('Enter your {{ type }}', { type: getTranslatedType(type, translate) })
}
