import React, { useContext } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { Icon, Typography } from '../../../../../../Components'
import { ThemeContext } from '../../../../../../Providers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

// The property `isActiveOnChildRoutes` indicates whether this navigationItem should be considered active on child routes.
// An example of an active child route is when `href` is `/foo` and the current route is `/foo/bar`.
export const NavigationItem: React.FC<IProps> = ({ isActiveOnChildRoutes = true, href, icon, name, onClick }) => {
  const navigate = useNavigate()
  const { isDarkSidebar } = useContext(ThemeContext)
  const isActive = Boolean(useMatch({ path: href, end: !isActiveOnChildRoutes }))
  const dataTestId = name.split(' ').join('-').toLocaleLowerCase()

  const classNames = ((): string => {
    const classes: string[] = [Styles.navigationItem]

    if (isActive) classes.push(Styles.active)
    if (isDarkSidebar) classes.push(Styles.dark)

    return classes.join(' ')
  })()

  const onClickHandler = (): void => {
    if (onClick) onClick()
    navigate(href)
  }

  return (
    <div data-testid={dataTestId} className={classNames} onClick={onClickHandler}>
      <Icon className={Styles.icon} name={icon} />
      <Typography className={Styles.name} variant="h2" element="span">
        {name}
      </Typography>
    </div>
  )
}
