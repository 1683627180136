import { t } from 'i18next'

import { ITravelPurposeEnum } from '../../../Lib/graphql'
import { IGpsOptions, ITravelPurposeOptions } from './interfaces'

export const gpsOptions = (): Array<IGpsOptions> => {
  return [
    {
      label: t('Business'),
      value: ITravelPurposeEnum.BUSINESS,
    },
    {
      label: t('Private'),
      value: ITravelPurposeEnum.PERSONAL,
    },
  ]
}

export const travelPurposeOptions = (): Array<ITravelPurposeOptions> => {
  return [
    {
      label: t('Business'),
      value: ITravelPurposeEnum.BUSINESS,
    },
    {
      label: t('Private'),
      value: ITravelPurposeEnum.PERSONAL,
    },
    {
      label: t('Commute'),
      value: ITravelPurposeEnum.COMMUTE,
    },
  ]
}

export const gpsOptionForValue = (value: ITravelPurposeEnum): IGpsOptions => {
  return gpsOptions().find((option) => option.value === value) || gpsOptions()[1]
}

export const travelPurposeOptionForValue = (value: ITravelPurposeEnum): ITravelPurposeOptions => {
  return travelPurposeOptions().find((option) => option.value === value) || travelPurposeOptions()[1]
}
