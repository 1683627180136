import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { useMutationHelper } from '../../Hooks/useMutationHelper'
import {
  GetTermsStatusDocument,
  ITermsAndConditionsDocumentKindEnum,
  useGetTermsAndConditionsLazyQuery,
  useGetTermsStatusQuery,
  useSetAgreeTermsMutation,
} from '../../Lib/graphql'
import { CurrentUserContext } from '../../Providers'
import { Button, Checkbox } from '../FormComponents'
import { Link } from '../HTMLComponents'
import { ModalContainer } from '../ModalContainer'
import { Typography } from '../Typography'
import Styles from './styles.module.scss'

interface IProps {
  shouldCompleteOnboarding: boolean
}

export const UpdatedTermsModal: React.FC<IProps> = ({ shouldCompleteOnboarding }): JSX.Element | null => {
  const { t } = useTranslation()
  const [documentIds, setDocumentIds] = useState<string[]>([])
  const { toastify } = useMutationHelper()
  const { setVisibility } = useContext(CurrentUserContext)
  const isOnboardingPath = location.pathname.startsWith('/welcome/')

  const { data: status } = useGetTermsStatusQuery({ skip: isOnboardingPath || shouldCompleteOnboarding })
  const [getTermsAndConditions, { data }] = useGetTermsAndConditionsLazyQuery()
  const [mutation, { loading }] = useSetAgreeTermsMutation()

  const me = status?.me
  const becameEmployee = me?.becameEmployee
  const hasNotAgreedToUpdatedGeneralTerms = me?.hasNotAgreedToUpdatedGeneralTerms
  const hasNotAgreedToUpdatedPrivacyStatement = me?.hasNotAgreedToUpdatedPrivacyStatement
  const terms = data?.me?.termsAndConditions?.filter((term) => !term.agreed) || []
  const agreed = terms?.every((term) => documentIds.includes(term?.document?.id || ''))
  const needsAgreement = Boolean(hasNotAgreedToUpdatedGeneralTerms || hasNotAgreedToUpdatedPrivacyStatement)
  const isVisible = Boolean(terms?.length) && needsAgreement && !becameEmployee
  const showModal = useMemo(
    () => !isOnboardingPath && isVisible && !shouldCompleteOnboarding,
    [isOnboardingPath, isVisible, shouldCompleteOnboarding]
  )

  const onClose = (): void => alert(t('This screen can only be closed if you agree with the user policy.'))

  useEffect(() => {
    setVisibility((prev) => ({ ...prev, updatedTermsAndCondition: showModal }))
  }, [showModal, setVisibility])

  useEffect(() => {
    const documentKinds: ITermsAndConditionsDocumentKindEnum[] = []
    if (hasNotAgreedToUpdatedGeneralTerms) documentKinds.push(ITermsAndConditionsDocumentKindEnum.GENERAL)
    if (hasNotAgreedToUpdatedPrivacyStatement) documentKinds.push(ITermsAndConditionsDocumentKindEnum.PRIVACY_STATEMENT)

    if (documentKinds.length > 0 && !becameEmployee) {
      getTermsAndConditions({
        variables: {
          kinds: documentKinds,
        },
      })
    }
  }, [becameEmployee, getTermsAndConditions, hasNotAgreedToUpdatedGeneralTerms, hasNotAgreedToUpdatedPrivacyStatement])

  const submit = (): void => {
    if (!agreed) return
    mutation({
      variables: { ids: documentIds.map((id) => id.toString()) },
      refetchQueries: [{ query: GetTermsStatusDocument }],
      awaitRefetchQueries: true,
    })
      .then((response) => {
        toastify(response.data?.setAgreeTerms?.result)
      })
      .catch((error: string) => {
        toast.error(t('We encountered an error during submit, please try again later...'), { autoClose: false })
        console.error('mutation failed with', error)
      })
  }

  const onChange = (id: string, checked: boolean): void => {
    if (checked) setDocumentIds((prevState) => [...prevState, id])
    else setDocumentIds((prevState) => prevState.filter((item) => item !== id))
  }

  const termsAndConditions = (
    documentName: string,
    url: string,
    type: ITermsAndConditionsDocumentKindEnum
  ): JSX.Element => {
    if (type === 'PRIVACY_STATEMENT') {
      return (
        <Trans
          i18nKey="I've read the <documentLink>{{ documentName }}</documentLink>."
          values={{
            documentName,
          }}
          components={{
            documentLink: <Link href={url} ariaLabel={t('Privacy statement.')} />,
          }}
        />
      )
    }

    return (
      <Trans
        i18nKey="I agree to the <documentLink>{{ documentName }}</documentLink>."
        values={{
          documentName,
        }}
        components={{
          documentLink: <Link href={url} ariaLabel={t('Terms and conditions agreement.')} />,
        }}
      />
    )
  }

  const text = ((): string => {
    const generalType = ITermsAndConditionsDocumentKindEnum.GENERAL
    const privacyType = ITermsAndConditionsDocumentKindEnum.PRIVACY_STATEMENT

    if (terms.every((item) => item.document.humanizedKind.value === generalType)) {
      return t(
        'The new terms and conditions apply to all products and services of {{ platform }}. These terms and conditions replace the current (card) terms.'
      )
    }

    if (terms.every((item) => item.document.humanizedKind.value === privacyType)) {
      return t(
        'The new privacy terms apply to all products and services of {{ platform }}. These privacy terms replace the current (card) terms.'
      )
    }

    return t(
      'The new user and privacy terms apply to all products and services of {{ platform }}. These terms replace the current (card) terms.'
    )
  })()

  const titleText = ((): string => {
    const generalType = ITermsAndConditionsDocumentKindEnum.GENERAL
    const privacyType = ITermsAndConditionsDocumentKindEnum.PRIVACY_STATEMENT

    if (terms.every((item) => item.document.humanizedKind.value === generalType))
      return t('{{ platform }} has new terms and conditions')

    if (terms.every((item) => item.document.humanizedKind.value === privacyType))
      return t('{{ platform }} has new privacy terms')

    return t('{{ platform }} has new terms and conditions')
  })()

  // Don't show in onboarding flow
  if (!showModal) return null

  return (
    <ModalContainer
      id="updated-statement-modal"
      className={Styles.modalContainer}
      title={t('New terms and conditions')}
      show={showModal}
      onClose={onClose}
      withPadding={false}
    >
      <Typography className={Styles.title} variant="h1">
        {titleText}
      </Typography>

      <Typography className={Styles.note} variant="body-2" element="p">
        {text}
      </Typography>

      {terms.length > 0 && (
        <div data-testid="terms-wrapper" className={Styles.termsWrapper}>
          {terms.map((term) => {
            const { document } = term
            return (
              <Checkbox
                key={document.id}
                id={`tc-checkbox-${document.id}`}
                className={Styles.checkbox}
                // @ts-ignore
                label={termsAndConditions(
                  document.humanizedKind.humanized || '',
                  document.pdfUrl,
                  document.humanizedKind.value
                )}
                checked={documentIds.includes(document.id)}
                onChange={(checked): void => onChange(document.id, checked)}
              />
            )
          })}
        </div>
      )}

      <Button
        id="agree-button"
        className={Styles.button}
        disabled={!agreed}
        loading={loading}
        onClick={submit}
        fullWidth
      >
        {t('Send')}
      </Button>
    </ModalContainer>
  )
}
