const licensePlateRegexes = [
  /^([A-Z]{2})(\d{2})(\d{2})$/, // 1     XX-99-99
  /^(\d{2})(\d{2})([A-Z]{2})$/, // 2     99-99-XX
  /^(\d{2})([A-Z]{2})(\d{2})$/, // 3     99-XX-99
  /^([A-Z]{2})(\d{2})([A-Z]{2})$/, // 4     XX-99-XX
  /^([A-Z]{2})([A-Z]{2})(\d{2})$/, // 5     XX-XX-99
  /^(\d{2})([A-Z]{2})([A-Z]{2})$/, // 6     99-XX-XX
  /^(\d{2})([A-Z]{3})(\d{1})$/, // 7     99-XXX-9
  /^(\d{1})([A-Z]{3})(\d{2})$/, // 8     9-XXX-99
  /^([A-Z]{2})(\d{3})([A-Z]{1})$/, // 9     XX-999-X
  /^([A-Z]{1})(\d{3})([A-Z]{2})$/, // 10    X-999-XX
  /^([A-Z]{3})(\d{2})([A-Z]{1})$/, // 11    XXX-99-X
  /^([A-Z]{1})(\d{2})([A-Z]{3})$/, // 12    X-99-XXX
  /^(\d{1})([A-Z]{2})(\d{3})$/, // 13    9-XX-999
  /^(\d{3})([A-Z]{2})(\d{1})$/, // 14    999-XX-9
]
export const checkForValidLicencePlate = (licensePlate: string): boolean => {
  if (licensePlate.length < 6) return false
  const plate = licensePlate.toUpperCase().replace(/-/g, '')
  const regex = licensePlateRegexes.find((reg) => reg.test(plate))
  return regex ? regex.test(plate) : false
}

const licensePlateFormatter = (unformattedLicensePlate: string): string => {
  const plate = unformattedLicensePlate.toUpperCase().replace(/-/g, '')
  const regex = licensePlateRegexes.find((reg) => reg.test(plate))
  return regex ? plate.replace(regex, '$1-$2-$3') : unformattedLicensePlate
}

export const formatLicensePlate = (licensePlate: string): string => {
  if (!checkForValidLicencePlate(licensePlate)) return licensePlate
  const formattedLicensePlate = licensePlateFormatter(licensePlate)
  if (formattedLicensePlate !== licensePlate) return formattedLicensePlate
  return licensePlate
}
