import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../FormComponents'
import { Icon } from '../../../Icon'
import { ContentSection } from '../../../Modal/Components'
import { Typography } from '../../../Typography'
import { getDescription, getTitle } from './helpers'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const LocationAlert: React.FC<IProps> = ({ type, onClick }): JSX.Element => {
  const { t } = useTranslation()

  return (
    <ContentSection className={Styles.wrapper}>
      <div className={Styles.container}>
        <Icon name="homepointer" className={Styles.icon} />

        <Typography variant="h1-large" className={Styles.title}>
          {getTitle(type, t)}
        </Typography>

        <Typography variant="body-2" className={Styles.text}>
          {getDescription(type, t)}
        </Typography>

        <Button id="next-button" variant="primary" size="big" onClick={onClick}>
          {t('Set location')}
        </Button>
      </div>
    </ContentSection>
  )
}
