import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Icon } from '../../../../Components'
import { Typography } from '../../../../Components/Typography'
import { useTranslationDecorator } from '../../../../Hooks/useTranslationDecorator'
import { ROUTE_NAMES } from '../../../../Layouts/Authorized/interfaces'
import { routes } from '../../../../Layouts/Authorized/routes'
import { formatCurrency } from '../../../../Lib/Formatters/currencyFormatter'
import { currentMonth } from '../../../../Lib/Formatters/DateFormatter'
import { Title } from '../../Components/Title'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const Monthly: React.FC<IProps> = ({ settlement }): JSX.Element => {
  const { t, locale } = useTranslationDecorator()
  const navigate = useNavigate()
  const chargeInCents = Math.abs(settlement?.totalGrossAndNetCharge.valueInCents || 0)
  const reimburseInCents = Math.abs(settlement?.totalGrossAndNetReimbursement?.valueInCents || 0)
  const financialResultInCents = reimburseInCents - chargeInCents

  return (
    <div data-testid="expected-payment">
      <Title title={t('Expected payments')} />

      <div className={Styles.row} onClick={(): void => navigate(routes[ROUTE_NAMES.SETTLEMENTS])}>
        <div>
          <Typography className={Styles.month} variant="body-2">
            {currentMonth({ locale, capitalize: true })}
          </Typography>
          <Typography data-testid="financial-result" className={Styles.amount} variant="h1" element="p">
            {formatCurrency(financialResultInCents, locale)}
          </Typography>
        </div>

        <Icon className={Styles.chevronIcon} name="chevron-right" width={12} height={12} />
      </div>
    </div>
  )
}
