import React from 'react'
import Skeleton from 'react-loading-skeleton'

import Styles from './styles.module.scss'

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <div className={Styles.mandateSkeleton}>
      <Skeleton containerClassName={Styles.title} width={90} height={20} />

      <div className={Styles.valuePair}>
        <Skeleton width={90} height={20} />
        <Skeleton width={120} height={20} />
      </div>

      <Skeleton containerClassName={Styles.buttonContainer} width={100} height={20} />
    </div>
  )
}
