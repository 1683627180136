export const convertSnakeOrKebabCaseToPascalCase = (wordcombination: string): string => {
  // Split wordcombination on 1 or more _ or -
  const wordParts = wordcombination.split(/[_-]+/g)

  // Iterate over these split parts. Be aware that a starting or trailing _- will result
  // into an empty string within the array.
  wordParts.forEach((wordPart: string, index: number): void => {
    if (wordPart === '') return
    wordParts[index] = wordPart.toLowerCase().replace(wordPart[0], wordPart[0].toUpperCase())
  })

  return wordParts.join('')
}

// Stolen from https://gist.github.com/nblackburn/875e6ff75bc8ce171c758bf75f304707#gistcomment-2874149
export const convertCamelCaseToKebabCase = (wordCombination: string): string => {
  return wordCombination
    .replace(/([a-z0-9])([A-Z])/g, '$1-$2')
    .replace(/([A-Z])([A-Z])(?=[a-z])/g, '$1-$2')
    .toLowerCase()
}
