import React from 'react'
import Skeleton from 'react-loading-skeleton'

import Styles from './styles.module.scss'

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <div className={Styles.invoiceSkeleton}>
      <div className={Styles.content}>
        <Skeleton width={90} height={20} />
        <Skeleton width={120} height={20} />
      </div>

      <Skeleton width={120} height={20} />
    </div>
  )
}
