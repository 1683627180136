import { t } from 'i18next'

import { ICountryIsoCodeEnum, ITravelPurposeEnum } from '../../Lib/graphql'
import { ICountryOptions, IGpsOptions } from './interfaces'

export const countryOptions = (): Array<ICountryOptions> => {
  return [
    {
      label: t('The Netherlands'),
      value: ICountryIsoCodeEnum.NL,
    },
    {
      label: t('Belgium'),
      value: ICountryIsoCodeEnum.BE,
    },
    {
      label: t('Germany'),
      value: ICountryIsoCodeEnum.DE,
    },
    {
      label: t('Luxembourg'),
      value: ICountryIsoCodeEnum.LU,
    },
    {
      label: t('Austria'),
      value: ICountryIsoCodeEnum.AT,
    },
    {
      label: t('Spain'),
      value: ICountryIsoCodeEnum.ES,
    },
    {
      label: t('France'),
      value: ICountryIsoCodeEnum.FR,
    },
  ]
}

export const gpsOptions = (): Array<IGpsOptions> => {
  return [
    {
      label: t('For business'),
      value: ITravelPurposeEnum.BUSINESS,
    },
    {
      label: t('Private'),
      value: ITravelPurposeEnum.PERSONAL,
    },
  ]
}

export const countryOptionForValue = (value: ICountryIsoCodeEnum): ICountryOptions | undefined => {
  return countryOptions().find((option) => option.value === value)
}

export const dateStringToDate = (dateString: string): Date => {
  return new Date(Date.parse(dateString))
}
