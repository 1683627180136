import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../FormComponents'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const ConfirmFooter: React.FC<IProps> = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  disableConfirm = false,
  showCancelOption = true,
  loading = false,
  confirmButtonSize,
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <footer className={Styles.confirmFooter}>
      {showCancelOption && (
        <Button
          id="cancel-button"
          data-testid="cancel-button"
          variant="noBorder"
          onClick={(): void => {
            if (onCancel) onCancel()
          }}
          textOnly
        >
          {cancelLabel || t('Cancel')}
        </Button>
      )}

      <Button
        id="confirm-button"
        data-testid="confirm-button"
        onClick={onConfirm}
        disabled={disableConfirm}
        loading={loading}
        {...(confirmButtonSize && { size: confirmButtonSize })}
      >
        {confirmLabel || t('Confirm')}
      </Button>
    </footer>
  )
}
