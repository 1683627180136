import React, { useContext } from 'react'

import { MediaQueriesContext } from '../../../Providers'
// eslint-disable-next-line reisbalans-rules/enforce-scss-modules
import Colors from '../../../Theme/colors.module.scss'
import { Icon } from '../../Icon'
import { IProps, TReturnType } from './interfaces'
import Styles from './styles.module.scss'

export const LegacyButton: React.FC<IProps> = ({
  'aria-label': ariaLabel,
  children,
  id,
  disabled,
  fullWidth = false,
  loading,
  className,
  smallScreenWidthOverride,
  width,
  icon,
  iconWidth,
  iconOnly,
  alignLeft = false,
  variant = 'primary',
  size = 'medium',
  textOnly = false,
  selected = false,
  iconWithBackground = false,
  animatedHoverEffect = true,
  ...otherProps
  // eslint-disable-next-line sonarjs/cognitive-complexity
}): JSX.Element => {
  const { isMDScreen } = useContext(MediaQueriesContext)

  // eslint-disable-next-line no-param-reassign
  if (!isMDScreen && smallScreenWidthOverride) width = smallScreenWidthOverride
  const renderWithoutIcon = (): TReturnType => children

  const renderChildren = (): JSX.Element => {
    if (!icon) return renderWithoutIcon() as JSX.Element
    return (
      <React.Fragment>
        <Icon
          data-testid={`${icon}-icon`}
          {...(iconWithBackground && {
            className: `${Styles.iconWithBackground} ${selected ? Styles.selected : ''}`,
            color: selected ? Colors.white : 'var(--primary-color)',
          })}
          name={icon}
          {...(iconWidth !== undefined && { width: iconWidth })}
        />
        {!iconOnly && children}
      </React.Fragment>
    )
  }

  // TODO: Remove old style after redesigning finished
  // ts-ignore temporary needed, will be removed when we undo old classNames
  const classNames = ((): string => {
    const classes: string[] = [Styles.button, Styles[size]]
    if (className) classes.push(className)
    if (variant === 'primary') classes.push(Styles.primary)
    if (variant === 'secondary') classes.push(Styles.secondary)
    if (variant === 'ovpay') classes.push(Styles.ovpay)
    if (variant === 'noBorder') classes.push(Styles.noBorder)
    if (variant === 'warning') classes.push(Styles.warning)
    if (loading) classes.push(Styles.loading)
    if (fullWidth) classes.push(Styles.fullWidth)
    if (iconOnly) classes.push(Styles.iconOnly)
    if (alignLeft) classes.push(Styles.alignLeft)
    if (selected) classes.push(Styles.selected)
    if (animatedHoverEffect) classes.push(Styles.animatedHoverEffect)

    return classes.join(' ')
  })()

  if (textOnly) {
    const classes: string[] = [Styles.textOnly]
    if (className) classes.push(className)
    if (loading) classes.push(Styles.loading)
    if (variant === 'secondary') classes.push(Styles.secondary)
    if (alignLeft) classes.push(Styles.alignLeft)
    if (animatedHoverEffect) classes.push(Styles.animatedHoverEffect)
    if (fullWidth) classes.push(Styles.fullWidth)

    return (
      <span id={id} className={classes.join(' ')} tabIndex={0} role="button" {...otherProps}>
        {children}
      </span>
    )
  }

  return (
    /* eslint-disable-next-line reisbalans-rules/prevent-vanilla-html-elements */
    <button
      {...otherProps}
      id={id}
      className={classNames}
      disabled={disabled || loading}
      type="submit"
      style={{ width }}
      tabIndex={0}
      aria-label={ariaLabel}
    >
      {renderChildren()}
    </button>
  )
}
