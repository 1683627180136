import React from 'react'

import { IProps } from './interfaces'
import { Invoices } from './Invoices'
import { Mandates } from './Mandates'
import { Monthly } from './Monthly'

/*
    eCurring is the old way of handling transactions.
 */
const ECurring: React.FC<IProps> = ({ invoices, mandates, financialConsequences, eMandateRequired }): JSX.Element => {
  return (
    <div>
      <Monthly settlement={financialConsequences} />
      {invoices.length > 0 && <Invoices invoices={invoices} />}
      <Mandates mandates={mandates} eMandateRequired={eMandateRequired} />
    </div>
  )
}

export default ECurring
