import { useEffect } from 'react'

import { IUseClickedOutsideHook } from './interfaces'

const getElementsBySelectors = (selectors: string[]): Element[] => {
  return selectors.reduce((acc, selector) => {
    const elements = [...document.querySelectorAll(selector)]

    return [...acc, ...elements]
  }, <Element[]>[])
}

export const useOnClickOutside = ({ handler, ref, preventSelectors = [] }: IUseClickedOutsideHook): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent): void => {
      const elements = getElementsBySelectors(preventSelectors)

      for (let i = 0; i < elements.length; i += 1) {
        const element = elements[i]

        if (element && element.contains(event.target as Node)) return
      }

      if (!ref?.current || ref.current.contains(event.target as Node)) return

      handler(event)
    }

    document.addEventListener('touchstart', listener)
    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('touchstart', listener)
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, handler, preventSelectors])
}
