import { IGetAllSettlementsQuery, IGetSettlementQuery, IGetTentativeSettlementQuery } from '../../../Lib/graphql'
import { IProps as IProgressBarProps } from './Components/ProgressBars/interfaces'
import { IProps as IResultsIntroProps } from './Components/ResultsIntro/interfaces'
import { IProps as IStatisticsProps } from './Components/Statistics/interfaces'
import { IProps as ITransactionData } from './Components/Transaction/interfaces'

export enum SettlementVariantEnum {
  GROSS = 'GROSS',
  NET = 'NET',
  ACCOUNTED_SETTLEMENT = 'ACCOUNTED_SETTLEMENT',
}

export type TMainOption = {
  label: string
  value: SettlementVariantEnum
}

export type TTentativeSettlement = NonNullable<
  NonNullable<NonNullable<IGetTentativeSettlementQuery['me']>['financialConsequences']>['tentativeSettlement']
>

type TFinancialSummary = NonNullable<TTentativeSettlement['financialSummary']>

export type TType = 'net' | 'gross'
export type TVariant = 'toReceive' | 'toPay'

type TEntry = {
  totalInCents: number
  data: ITransactionData[]
}

export type TMappedFinancialSummary = { toReceive: TEntry; toPay: TEntry }

export type TAllSettlement = NonNullable<
  NonNullable<NonNullable<NonNullable<IGetAllSettlementsQuery['me']>['financialConsequences']>['settlements']>[0]
>

export type TSingleSettlement = NonNullable<
  NonNullable<NonNullable<IGetSettlementQuery['me']>['financialConsequences']>['settlement']
>

export type TSettlement = TSingleSettlement | TTentativeSettlement

export type TSubOption = { label: string; value: TAllSettlement | null }

export interface IUseResultsHook {
  getResultsIntroProps: () => IResultsIntroProps
  getStatisticsProps: () => IStatisticsProps
  getProgressBarsProps: () => IProgressBarProps
  mapToTransaction: (event: TFinancialSummary[0], variant: TVariant, type: TType) => ITransactionData
  getMappedFinancialSummary: (type: TType) => TMappedFinancialSummary
}
