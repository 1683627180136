import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'

import { Icon } from '../../../Icon'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const TaskToggle: React.FC<IProps> = ({
  collapsed = true,
  numTasks,
  loading = false,
  onClick,
}): JSX.Element | null => {
  const { t } = useTranslation()
  return (
    <div
      aria-label={`${collapsed ? t('Expand list of') : t('Collapse list of')} ${numTasks} ${t('Tasks')}`}
      className={Styles.toggleContainer}
      onClick={onClick}
      data-testid="task-toggle-button"
      id="task-toggle-button"
      role="button"
      tabIndex={0}
    >
      {loading ? (
        <Skeleton width={35} style={{ backgroundColor: 'transparent' }} />
      ) : (
        <React.Fragment>
          <Icon width={16} height={16} name="bell" className={Styles.bellIcon} />
          {numTasks > 0 && <span className={Styles.notification}>{numTasks}</span>}
        </React.Fragment>
      )}
    </div>
  )
}
