import React from 'react'

import Styles from '../../FormComponents/LegacyButton/styles.module.scss'
import { Link } from '../Link'
import { IProps } from './interfaces'

export const ButtonLink: React.FC<IProps> = ({
  className = '',
  fullWidth = false,
  variant = 'primary',
  size = 'medium',
  ...linkProps
}) => {
  const classNames = ((): string => {
    const classes: string[] = [Styles.button, Styles[size], className]
    // @ts-ignore
    if (variant === 'primary') classes.push(Styles.primary)
    // @ts-ignore
    if (variant === 'secondary') classes.push(Styles.secondary)
    // @ts-ignore
    if (variant === 'noBorder') classes.push(Styles.noBorder)
    if (fullWidth) classes.push(Styles.fullWidth)
    return classes.join(' ')
  })()

  return <Link className={classNames} withUnderline={false} {...linkProps} />
}
