import React from 'react'

import { CurvedSection } from '../CurvedSection'
import { Typography } from '../index'
import { IProps } from './interfaces'
import Styles from './styles.module.scss'

export const AnimatedSection: React.FC<IProps> = ({ title, description, image }): JSX.Element => {
  return (
    <CurvedSection withShadow>
      <div className={Styles.container}>
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img className={Styles.gif} src={image} alt="image" />

        <div className={Styles.wrapper}>
          <Typography variant="h1" className={Styles.title}>
            {title}
          </Typography>

          <Typography variant="body-2" className={Styles.text}>
            {description}
          </Typography>
        </div>
      </div>
    </CurvedSection>
  )
}
