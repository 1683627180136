import React from 'react'
import Skeleton from 'react-loading-skeleton'

import { LoadingSkeleton as InvoiceSkeleton } from '../Invoice/LoadingSkeleton'
import { LoadingSkeleton as MandateSkeleton } from '../Mandate/LoadingSkeleton'
import Styles from './styles.module.scss'

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <div>
      <div>
        <Skeleton className={Styles.title} width={100} height={20} />
        {Array.from(Array(3).keys(), (item) => (
          <InvoiceSkeleton key={`invoice-skeleton-${item}`} />
        ))}
      </div>

      <div>
        <Skeleton className={Styles.title} width={100} height={20} />
        {Array.from(Array(3).keys(), (item) => (
          <MandateSkeleton key={`mandate-skeleton-${item}`} />
        ))}
      </div>
    </div>
  )
}
